// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, {Component} from 'react'
import { Label } from 'react-bootstrap'
import Select from 'react-select'

import * as activeActions from '../../actions/active'
import {entityIsNew} from '../../util/objects'

import type {Pattern, GtfsRoute, Feed, ServiceCalendar, Trip, TripCounts} from '../../../types'

type CalendarOption = {
  calendar: ServiceCalendar,
  calendarTrips: number,
  label: string,
  patternTrips: number,
  service_id: string,
  totalTrips: number,
  value: string
}

type Props = {
  calendars: Array<ServiceCalendar>,
  serviceId: String
}

export default class CalendarSelectInTimetable extends Component<Props> {

  _optionRenderer = (option: CalendarOption) => {
    const {
      label,
      service_id: serviceId,
      patternTrips,
      totalTrips,
      totalActiveDays,
    } = option
    // FIXME: Add back route count and route trips for calendar?
    return (
      <span title={label}>
        {serviceId}
        {' '}
        <Label
          title={`Il calendario ha ${totalActiveDays} giorni di servizio`}>
          <Icon type='calendar-check-o' /> {totalActiveDays}
        </Label>

      </span>
    )
  }

  _onChange = (value: CalendarOption) => {
    if(value) this.props.onChange(value.service_id)
  }


  _getOptions = (): Array<CalendarOption> => {
    const {calendars} = this.props
    const calendarOptions: Array<CalendarOption> = calendars
      ? calendars
        .map(calendar => ({
          label: calendar.description || calendar.service_id,
          value: calendar.service_id,
          service_id: calendar.service_id,
          totalActiveDays: calendar.numberActiveDays,
        }))
      : []
    return calendarOptions
      .sort((a, b) => {

        return a.service_id.localeCompare(b.service_id)
        // return b.label - a.label

        // if (route.id in a.routes && !(route.id in b.routes)) return -1
        // else if (route.id in b.routes && !(route.id in a.routes)) return 1
        // else return b.numberOfTrips - a.numberOfTrips
      })
  }

  render () {
    const customStyles = {
      control: base => ({
        ...base,
        height: 25,
        minHeight: 25
      })
    }

    const {
      serviceId
    } = this.props
    return (
      <Select
        clearable={false}
        z-index={1000}
        value={serviceId}
        placeholder={<span><Icon type='calendar-o' /> Seleziona calendario...</span>}
        valueRenderer={this._optionRenderer}
        optionRenderer={this._optionRenderer}
        options={this._getOptions()}
        onChange={this._onChange}
        styles={customStyles}
        filterOptions />
    )
  }
}
