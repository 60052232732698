import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import LogoAmp from './LogoAmp.js'
import LogoCasale from './LogoCasale.js'
import LogoBardo from './LogoBardo.js'
import {getConfigProperty} from '../../../../common/util/config'

const NUM_TRIPS_PER_VOLTA = 3

const formatDatiLinea = (patternsWithSchedulesData, feedInfo) => {
	return {
		table:{
			body: [
				// [{text: 'CANOVA'},{text: routeReportDataTotals.numRoutes, alignment: 'right'}],
				[{text: `Linea ${patternsWithSchedulesData[0].routeShortName} - ${patternsWithSchedulesData[0].routeName}`, fontSize: 12, bold: true, }],
				[{text: `Esercita da ${patternsWithSchedulesData[0].agencyName}`}],
			]
		},
		layout: 'headerLineOnly'
	}
}

const formatSchedules = (stopSeq, tripIndex) => {
	let SchedulesCells = []
	Array.from({length: NUM_TRIPS_PER_VOLTA}).forEach((n, _012) => {
		if(stopSeq[`t${tripIndex + _012}_arrivalTime`]){
			SchedulesCells.push({text: stopSeq[`t${tripIndex + _012}_arrivalTime`], alignment: 'center'})
			// SchedulesCells.push({text: stopSeq[`t${tripIndex + _012}_departureTime`], alignment: 'center'})
		}
	})
	// console.log('SchedulesCells', SchedulesCells)
	return SchedulesCells
}

const formatSchedulesRow = (pea, tripIndex) => {
	return pea.map(stopSeq => {

		return ([
      {text: stopSeq.stopSequence, alignment: 'right'},
			{text: stopSeq.stopCode},
			{text: stopSeq.stopName},
			...formatSchedules(stopSeq, tripIndex)

			// {text: stopSeq.t0_arrivalTime, alignment: 'center'},
			// {text: stopSeq.t0_departureTime, alignment: 'center'},
			// {text: stopSeq.t1_arrivalTime, alignment: 'center'},
			// {text: stopSeq.t1_departureTime, alignment: 'center'},
		]);
	});
}

const getFirstdRowHeader = (trips, tripIndex) => {
	let headers = [
		{text: '#', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'/*, rowSpan: 2, margin:[0,50,0,0]*/},
		{text: 'Fermata', style: 'tableHeader', fillColor: '#eeeeee'/*, rowSpan: 2, margin:[0,50,0,0]*/},
		{text: 'Codice corsa', style: 'tableHeader', fillColor: '#eeeeee', alignment: 'right'/*, rowSpan: 2, margin:[0,50,0,0]*/},
		// ...getTripsHeaderColumns(pattern.trips, tripIndex)
	]
	Array.from({length: NUM_TRIPS_PER_VOLTA}).forEach((n, _012) => {
		if(trips[tripIndex + _012]){
			headers.push({text: `${trips[tripIndex + _012].trip_short_name} ${trips[tripIndex + _012].confirmation_trip ? 'AC' : '' }`,bold: true, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'/*, colSpan: 2*/})
			// headers.push({})
		}
	})
	// console.log('getFirstdRowHeader', headers)
	return headers
}
const getSecondRowHeader = (trips, tripIndex) => {
	let headers = [{}, {}, {text: 'Calendario:', alignment: 'right', style: 'tableHeader'}]
	Array.from({length: NUM_TRIPS_PER_VOLTA}).forEach((n, _012) => {
		if(trips[tripIndex + _012]){
			headers.push({text: `${trips[tripIndex + _012].service_id}`, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'/*, colSpan: 2*/})
			// headers.push({text: `a`, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'})
			// headers.push({text: `p`, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'})
		}
	})
	// console.log('getSecondRowHeader', headers)
	return headers
}
const getThirdRowHeader = (trips, tripIndex, feedInfo) => {
	let headers = [{}, {}, {text: 'Inizio:', alignment: 'right', style: 'tableHeader'}]
	const dateFormat = getConfigProperty('application.date_format') || ''
	Array.from({length: NUM_TRIPS_PER_VOLTA}).forEach((n, _012) => {
		if(trips[tripIndex + _012]){
			//${moment(feedInfo.feed_start_date).format(dateFormat)}
			const start_date = trips[tripIndex + _012].start_date ? moment(trips[tripIndex + _012].start_date).format(dateFormat) : moment(feedInfo.feed_start_date).format(dateFormat)
			headers.push({text: start_date, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee', bold: trips[tripIndex + _012].start_date ? true : false})
		}
	})
	// console.log('getSecondRowHeader', headers)
	return headers
}
const getFourthRowHeader = (trips, tripIndex, feedInfo) => {
	let headers = [{}, {}, {text: 'Fine:', alignment: 'right', style: 'tableHeader'}]
	const dateFormat = getConfigProperty('application.date_format') || ''
	Array.from({length: NUM_TRIPS_PER_VOLTA}).forEach((n, _012) => {
		if(trips[tripIndex + _012]){
			const end_date = trips[tripIndex + _012].end_date ? moment(trips[tripIndex + _012].end_date).format(dateFormat) :  moment(feedInfo.feed_end_date).format(dateFormat)
			headers.push({text: end_date, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee', bold: trips[tripIndex + _012].end_date ? true : false})
		}
	})
	// console.log('getSecondRowHeader', headers)
	return headers
}

const getWidths = (trips, tripIndex) => {
	let widths = [15, 60, '*']
	Array.from({length: NUM_TRIPS_PER_VOLTA}).forEach((n, _012) => {
		if(trips[tripIndex + _012]){
			widths.push(60)
			// widths.push(30)
		}
	})
	// console.log('widths', widths)
	return {widths}
}

const getTripsTables = (pattern, feedInfo) => {
	let tripsTables = []
	Array.from({length: pattern.trips.length}).forEach((n, tripIndex)=>{
		if (tripIndex % NUM_TRIPS_PER_VOLTA === 0){
			const formattedSchedules = formatSchedulesRow(pattern.pea, tripIndex)
			tripsTables.push(
				{
					table: {
						headerRows: 4,
						keepWithHeaderRows: 1,
						dontBreakRows: true,
						// widths: [ 15, 45, '*', 30, 30, 30, 30],
						// widths: [ 15, 45, 80, 30, 30],
						...getWidths(pattern.trips, tripIndex),
						body: [
							// [
							// 	{text: '#', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee', rowSpan:2},
							// 	{text: 'Fermata', style: 'tableHeader', fillColor: '#eeeeee', rowSpan:2},
							// 	{text: 'Nome fermata', style: 'tableHeader', fillColor: '#eeeeee', rowSpan:2},
							// 	...getTripsHeaderColumns(pattern.trips, tripIndex)
							// ],

								// [
								// 	{text: '#', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee', rowSpan: 2},
								// 	{text: 'Fermata', style: 'tableHeader', fillColor: '#eeeeee', rowSpan: 2},
								// 	{text: 'Nome fermata', style: 'tableHeader', fillColor: '#eeeeee', rowSpan: 2},
								// 	{text: `tpippo`, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee', colSpan: 2},
								// 	{},
								// ],
								// [{}, {}, {},
								// 	{text: `a`, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'},
								// 	{text: `p`, style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'}
								// ],

							[...getFirstdRowHeader(pattern.trips, tripIndex)],
							[...getSecondRowHeader(pattern.trips, tripIndex)],
							[...getThirdRowHeader(pattern.trips, tripIndex, feedInfo)],
							[...getFourthRowHeader(pattern.trips, tripIndex, feedInfo)],
							...formattedSchedules,
						]
					},
					layout: 'lightHorizontalLines', // optional
				},
				'\n',
			)

		}
	})
	return tripsTables

}
const formatPatterns = (patternsWithSchedulesData, feedInfo) => {

	const ret = patternsWithSchedulesData.map( (pattern) => {

		// console.log('pattern.trips.length',pattern.trips.length )
		const patternLengthFormatted = pattern.trips[0] ? (pattern.trips[0].official_length / 1000).toLocaleString('it', {maximumFractionDigits: 1}) : 0
		const tipoServizio = pattern.trips[0]
						? pattern.trips[0].trip_type === '0' ? 'urbano' : 'extraurbano'
						: '-'
		return [
			{text: [{text:'Percorso: ', bold: true}, `${pattern.id} - ${pattern.name}`], margin: [ 0, 10, 0, 5 ] },
			{text: [{text:`- Lunghezza:  ${patternLengthFormatted} Km`}, {text:`       - Tipo servizio: ${tipoServizio} `}] , margin: [ 0, 0, 0, 5 ]  },
			getTripsTables(pattern, feedInfo),
			'\n',
			'\n',
		]
	})

	// console.log('ret', ret)
	return ret.flat()
}

const bigNumberFormatter = (val) => {
	return (val === 0 || val)? val.toLocaleString('it', {maximumFractionDigits: 0}) : null
}

export default (patternsWithSchedulesData, feedInfo/*, tripSchedulesTableDataTotals*/) => {
	const {vfs} = vfsFonts.pdfMake
	pdfMake.vfs = vfs

	const dateFormat = getConfigProperty('application.date_format') || ''
	const datiLinea = formatDatiLinea(patternsWithSchedulesData)
	const patterns = formatPatterns(patternsWithSchedulesData, feedInfo)
	// const formattedRouteReportData = formatRouteReportData(routeReportData)
	// const formatRouteReportDataTotalsTable = formatRouteReportDataTotals(routeReportDataTotals)
	// console.log('formatRouteReportDataTotalsTable', formatRouteReportDataTotalsTable)

	let logo = 	{
		image: 'logoAmp',
		width: 50,
		height: 50,
		absolutePosition: { x: 750, y: 30 }
	}

	if (feedInfo.feed_publisher_name.indexOf('Casale') >= 0){
		logo.image = 'logoCasale'
	}
	else if (feedInfo.feed_publisher_name.indexOf('Bardonecchia') >= 0){
		logo.image = 'logoBardonecchia'
	}

  const documentDefinition = {
		info: {
	    title: `Contratto di servizio  ${feedInfo.feed_publisher_name}`,
	    author: 'Café team',
			creator: '5T',
			producer: 'AMM',
	    subject: 'Contratto di servizio',
	    keywords: 'trasporto pubblico, servizio, contratto, 5t',
	  },
		pageSize: 'A4',
		pageOrientation: 'landscape',
		defaultStyle: {
	    fontSize: 10,
	  },
		footer: (currentPage, pageCount) => { return {text: 'pag. ' + currentPage.toString() + ' / ' + pageCount, alignment: 'right', margin: [10, 0] }},
		content: [
			logo,
			{text: 'Contratto di servizio', fontSize: 20, bold: true},
			{text: `${feedInfo.feed_publisher_name}`, fontSize: 20, bold: true},
			{text: `Validità dal ${moment(feedInfo.feed_start_date).format(dateFormat)} al  ${moment(feedInfo.feed_end_date).format(dateFormat)}  ${feedInfo.cod_contratto ?  `-    Cod. Contratto ${feedInfo.cod_contratto}` : ''}`, fontSize: 12},
			//{text: `Cod. Contratto ${feedInfo.cod_contratto}`},
			{text: 'Report Orari', fontSize: 14, style:{color: 'blue', italics: true, }},
			'\n',
			'\n',
			datiLinea,
			...patterns,
			// {
			// 	table: {
			// 		headerRows: 1,
			// 		dontBreakRows: true,
			// 		widths: [ '25%', '*',60, 40, 60, 60],
			// 		body: [
			// 			[
			// 				{text: 'Corsa', style: 'tableHeader', fillColor: '#eeeeee'},
			// 				{text: 'Calendario', style: 'tableHeader', fillColor: '#eeeeee'},
			// 				{text: 'N. gg serv', style: 'tableHeader', fillColor: '#eeeeee'},
			// 				{text: 'Contrib', style: 'tableHeader', fillColor: '#eeeeee'},
			// 				{text: 'Inizio', style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'},
			// 				{text: 'Fine', style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'},
			// 			],
			// 			...patterns,
			// 		]
			// 	},
			// 	layout: 'lightHorizontalLines', // optional
			// }
		],
		images: {
			...LogoAmp, ...LogoCasale, ...LogoBardo
		},
  }
	const pdf = pdfMake.createPdf(documentDefinition)
	// pdf.download()
	pdf.open()
}
