// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, { Component } from 'react'
import { Alert, Checkbox, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table/lib/BootstrapTable'
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn'
import { CSVLink, CSVDownload } from "react-csv"
import {Button, ListGroupItem} from 'react-bootstrap'
import moment from 'moment'
import { orderBy } from 'natural-orderby'

import Loading from '../../../../common/components/Loading'
import ActiveDateTimeFilter from '../containers/ActiveDateTimeFilter'
import { getConfigProperty, getComponentMessages } from '../../../../common/util/config'
import tripsReportLayoutPdf from './TripsReportLayoutPdf'

import type {
  AllRoutesSubState,
  PatternsState,
  TimetablesState
} from '../../../../types/reducers'

type Props = {
  filter: {
    patternFilter: ?string,
    routeFilter: ?string,
    // showArrivals: boolean,
    // timepointFilter: boolean
  },
  onComponentMount: (Props) => void,
  patternFilterChange: any => void,
  patterns: PatternsState,
  routeFilterChange: (?string) => void,
  routes: AllRoutesSubState,
  // showArrivalsToggle: () => void,
  tableOptions: any,
  // timepointFilterToggle: () => void,
  // timetableDateTimeFilterChange: () => void,
  tripsTableData: {
    columns: Array<any>,
    rows: Array<any>
  },
  timetables: TimetablesState,
  version: any
}


export default class TripsReportLayout extends Component<Props> {
  messages = getComponentMessages('TripsReportLayout')
  dateFormat = getConfigProperty('application.date_format') || ''

  componentDidMount () {
    // console.log('qui this.props', this.props)    
    this.props.onComponentMount(this.props)
  }

  _onClick () {
    this.props.trips.allRoutes.fetchStatus.fetched ?
      this.refs.myCSVLink.link.click() :
      this.props.getAllPatternInformations()
  }

  downloadCSV () {
    this.props.setAllcsvStatus()
    setTimeout(() => {this.refs.myCSVLink.link.click()}, 500)
    //this.refs.myCSVLink.link.click()
  }

  clickLinkRouteCSV () {
    this.refs.myCSVRouteLink.link.click()
  }

  bigNumberFormatter(val){
    return (val === 0 || val) ? val.toLocaleString('it', {maximumFractionDigits: 0}) : null
  }
  // future usage
  oneDecimalFormatter(val){
    return (val === 0 || val) ? val.toLocaleString('it', {maximumFractionDigits: 1}) : null
  }

  dateFormatter = (val) => {
    return (val.substring(4) === '0101' || val.substring(4) === '1231')
    ?  <span style={{color: '#999'}}>{moment(val).format(this.dateFormat)}</span>
    :  moment(val).format(this.dateFormat)
  }

  booleanFormatter = (val) => {
    return val === 1 ? '✓' : '✗'

  }

  render () {
    const {
      filter,
      patternFilterChange,
      patterns,
      routeFilterChange,
      routes,
      tableOptions,
      getAllPatternInformations,
      setAllcsvStatus,
      tripsTableData,
      tripsDataTotals,
      trips,
      allCSVData,
      routeCSVData,
      version,
      feedInfo,
      routeCalendars
    } = this.props

    const tripsTableOptions = {...tableOptions, pagination: false, exportCSV: false, search: false}

    // console.log('tripsTableData', tripsTableData)
    // console.log('tripsDataTotals', tripsDataTotals)
    const {
      patternFilter,
      routeFilter,
      // showArrivals,
      // timepointFilter
    } = filter

    const allFiltersSelected = routeFilter // 5t && patternFilter

    const testalino = allFiltersSelected && trips.oneRoute.fetchStatus.fetched && tripsTableData.length > 0 &&
      (<div>
        {/* <span style={{marginLeft: 60}}>{tripsTableData[0].routeName}</span> */}
        <span><b>Esercita da: </b>{tripsTableData[0].agencyName}</span>
        <br />
        <span>Totale Km di linea: {this.bigNumberFormatter(tripsDataTotals.totKmEffettivi)}</span>
        <br />
        <span>Totale Km contribuiti: {this.bigNumberFormatter(tripsDataTotals.totKmEffettiviContrib)}</span>
        <br />
        <span>Totale Km a conferma: {this.bigNumberFormatter(tripsDataTotals.totKmEffettiviConferma)}</span>
        <Button
          className='pull-right'
          bsStyle={'success'}
          onClick={this.clickLinkRouteCSV.bind(this)}>
          <span>
            <Icon type='download'/>
            {this.messages('downloadCurrRouteCSV')}
          </span>
        </Button>
        <CSVLink data={routeCSVData} filename={"reportCorseLinea"+tripsTableData[0].numRoute+".csv"} ref="myCSVRouteLink"/>
        <Button
          style={{margin: '0 5px'}}
          className='pull-right'
          bsStyle={'warning'}
          onClick={() => tripsReportLayoutPdf(tripsTableData, tripsDataTotals, feedInfo, routeCalendars)}
          >
          <span>
            <Icon type='file-text'/> {this.messages('createRouteReport')}
          </span>
        </Button>
      </div>)

    const tabelloni = allFiltersSelected && trips.oneRoute.fetchStatus.fetched && tripsTableData.length > 0 &&
      tripsTableData.map((pattern, idx) => {
        // console.log('pattern!', pattern)
        const patternLengthFormatted = pattern.trips[0] ? ( pattern.trips[0].official_length / 1000).toLocaleString('it', {maximumFractionDigits: 1}) : 0
        const tipoServizio = pattern.trips[0]  && pattern.trips[0].trip_type === 0 ? 'urbano' : 'extraurbano'
        return (
          <div key={idx}>
            <p style={{marginTop: '35px'}}><b>{this.messages('pattern')}: </b>{pattern.id} - {pattern.name}</p>
              {pattern.trips[0] && pattern.trips[0].official_length &&
                <p>
                  - Lunghezza del percorso: {patternLengthFormatted} km  <br />
                  - Tipo servizio: {tipoServizio}
                </p>
              }

            <BootstrapTable
              {...tripsTableOptions}
              headerStyle={{fontSize: 'small', textWrap: 'normal', wordWrap: 'break-word', whiteSpace: 'no-wrap'}}
              bodyStyle={{fontSize: 'small'}}
              data={pattern.trips}>
                <TableHeaderColumn dataSort dataField='trip_short_name' isKey width='130px'>{this.messages('corsa')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='service_id' width='20%'>{this.messages('calendario')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='sliced_days' width='100px' dataAlign='right'>{this.messages('sliced_days')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='contributed' dataFormat={this.booleanFormatter} width='80px' dataAlign='center'>{this.messages('contrib')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='confirmation_trip' dataFormat={this.booleanFormatter} width='80px' dataAlign='center'>AC</TableHeaderColumn>
                {/* <TableHeaderColumn dataSort dataField='km_tot'>{this.messages('km_tot')}</TableHeaderColumn> */}
                <TableHeaderColumn dataSort dataField='kmEffettivi' dataFormat={this.bigNumberFormatter} dataAlign='right'>{this.messages('kmEffettivi')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='seat' dataAlign='right'>{this.messages('seat')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='stand' dataAlign='right'>{this.messages('stand')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='start_date' dataFormat={this.dateFormatter} dataAlign='center'>{this.messages('inizio')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='end_date' dataFormat={this.dateFormatter} dataAlign='center'>{this.messages('fine')}</TableHeaderColumn>
            </BootstrapTable>
          </div>
      )
      })

    return (
      <div>
      <Row style={{ marginBottom: '20px' }}>
      <Col xs={12} md={2}>
          <Button
            bsStyle={'success'}
            onClick={this._onClick.bind(this)}>
            {
              <span>
              {
                trips.allRoutes.fetchStatus.fetching ?
                <Icon className='fas fa-refresh fa-spin' type='refresh'/> :
                <Icon type='download'/>
              }
              {this.messages('downloadAllRoutesCSV')}
              </span>
            }
          </Button>
          <CSVLink data={allCSVData} filename="reportCorse.csv" ref="myCSVLink"/>
          <br /><a href={process.env.PUSA_URL + "/utility/servicestrings?schema=" + version.namespace}><small>Scarica stringhe calendari</small></a>
        </Col>
      </Row>
        <Row style={{ marginBottom: '20px' }}>
          <Col xs={12} md={6}>
            <label htmlFor='route_name'>{this.messages('route')}:</label>
            <Select
              options={orderBy(routes.data, route => route.route_short_name) || []}
              labelKey={'route_name'}
              valueKey={'route_id'}
              placeholder={this.messages('selectLine')}
              value={routeFilter}
              onChange={routeFilterChange} />
          </Col>
          {routeFilter &&
            <Col xs={12} md={6}>
              <label htmlFor='pattern'>{this.messages('pattern')}:</label>
              {/*5t messa label labelKey={'name'}*/}
              <Select
                options={patterns.data.patterns}
                valueKey={'pattern_id'}
                placeholder={this.messages('patternFilter')}
                value={patternFilter}
                onChange={patternFilterChange} />
            </Col>
          }
        </Row>
        {/*<ActiveDateTimeFilter
          onChange={timetableDateTimeFilterChange}
          version={version} />*/}

        {trips.oneRoute.fetchStatus.fetching &&
          <Loading />
        }
        {trips.oneRoute.fetchStatus.error &&
          <Alert bsStyle='danger'>
            {this.messages('error_fetch')}
          </Alert>
        }
        {!allFiltersSelected &&
          <Alert>
            {this.messages('select2consult')}
          </Alert>
        }
        {testalino}
        {tabelloni}
        {allFiltersSelected && trips.oneRoute.fetchStatus.fetched && tripsTableData.length === 0 &&
          <Alert bsStyle='warning'>
            {this.messages('noTripsFound')}
          </Alert>
        }
        {trips.allRoutes.fetchStatus.fetched && !trips.allRoutes.fetchStatus.CSV_downloaded && allCSVData.length > 0 &&
          this.downloadCSV()
        }
      </div>
    )
  }
}
