// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, { Component } from 'react'
import { Alert, Checkbox, Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table/lib/BootstrapTable'
import TableHeaderColumn from 'react-bootstrap-table/lib/TableHeaderColumn'
import { CSVLink, CSVDownload } from "react-csv"
import {Button, ListGroupItem} from 'react-bootstrap'
import moment from 'moment'
import { orderBy } from 'natural-orderby'

import Loading from '../../../../common/components/Loading'
import ActiveDateTimeFilter from '../containers/ActiveDateTimeFilter'
import { getComponentMessages, getConfigProperty } from '../../../../common/util/config'
import scheduleReportLayoutPdf from './ScheduleReportLayoutPdf'


import type {
  AllRoutesSubState,
  PatternsState,
  TimetablesState
} from '../../../../types/reducers'

type Props = {
  filter: {
    patternFilter: ?string,
    routeFilter: ?string,
    // showArrivals: boolean,
    // timepointFilter: boolean
  },
  onComponentMount: (Props) => void,
  patternFilterChange: any => void,
  patterns: PatternsState,
  routeFilterChange: (?string) => void,
  routes: AllRoutesSubState,
  // showArrivalsToggle: () => void,
  tableOptions: any,
  // timepointFilterToggle: () => void,
  // timetableDateTimeFilterChange: () => void,
  tripsTableData: {
    columns: Array<any>,
    rows: Array<any>
  },
  timetables: TimetablesState,
  version: any
}

export default class ScheduleReportLayout extends Component<Props> {
  messages = getComponentMessages('ScheduleReportLayout')
  dateFormat = getConfigProperty('application.date_format') || ''
  //csvLink = React.createRef()
  componentDidMount () {
    this.props.onComponentMount(this.props)
  }

  _onClick () {
    this.props.tripschedules.allRoutes.fetchStatus.fetched ?
      this.refs.myCSVLink.link.click() :
      this.props.getAllPatternInformations()
  }

  downloadCSV () {
    this.props.setAllcsvStatus()
    setTimeout(() => {this.refs.myCSVLink.link.click()}, 500)
    //this.refs.myCSVLink.link.click()
  }

  clickLinkRouteCSV () {
    this.refs.myCSVRouteLink.link.click()
  }


  terminatedFormat(cell, row){
    return `<span style='color: #999'>${cell}</span>`;
  }

  flatFormat(cell, row){
    return cell
  }

  formatData(val){
    return val ? moment(val).format(this.dateFormat) : ''
  }

  getTripsHeaderColumns(pattern) {

    let headers = []
    pattern.trips.forEach((trip, j) => {
      const AC = trip.confirmation_trip ? 'AC' : ''
      const color = trip.end_date ? {color: '#999'} : {}
      const dataFormat = trip.end_date ? this.terminatedFormat : this.flatFormat
      const dataInizio = this.formatData(trip.start_date)
      const dataFine = this.formatData(trip.end_date)
      headers.push(<TableHeaderColumn key={j} width='100px' row='0' headerAlign='center' title='Codice corsa'><span style={color}>{`${trip.trip_short_name} ${AC}`}</span></TableHeaderColumn>)
      headers.push(<TableHeaderColumn title='Calendario' key={`t${j}_service_id`} width='100px' row='1'  headerAlign='center' dataAlign = 'center' dataField={`t${j}_arrivalTime`} dataFormat={dataFormat} ><span style={color}>{trip.service_id}</span></TableHeaderColumn>)
      headers.push(<TableHeaderColumn key={`t${j}_start_date`} width='100px' row='2'  headerAlign='center' dataAlign = 'center' dataField={`t${j}_arrivalTime`} ><span title='inizio validità corsa' style={color}>{dataInizio}</span></TableHeaderColumn>)
      headers.push(<TableHeaderColumn key={`t${j}_end_date`} width='100px' row='3'  headerAlign='center' dataAlign = 'center' dataField={`t${j}_arrivalTime`} dataFormat={dataFormat} ><span title='fine validità corsa' style={color}>{dataFine}</span></TableHeaderColumn>)
    })
    return headers
  }

  render () {
    const {
      filter,
      patternFilterChange,
      patterns,
      routeFilterChange,
      routes,
      getAllPatternInformations,
      tableOptions,
      patternsWithSchedulesData,
      setAllcsvStatus,
      allCSVData,
      routeCSVData,
      tripschedules,
      version,
      feedInfo
    } = this.props
    const {
      patternFilter,
      routeFilter,
    } = filter
    const tripsTableOptions = {...tableOptions, exportCSV: false, search: false, pagination: false/*sovrascritto*/}
    const allFiltersSelected = routeFilter // 5t && patternFilter
    const urlReportCsv = `/dbu/export/csvOrari?schema=${version.namespace}`
    const urlCompanyReportCsv = `/dbu/export/csvOrariAziende?schema=${version.namespace}`
    

    const testalino = allFiltersSelected && tripschedules.oneRoute.fetchStatus.fetched && patternsWithSchedulesData.length > 0 &&
      (<div style={{marginBottom: '30px'}}>
        <span><b>Esercita da: </b>{patternsWithSchedulesData[0].agencyName}</span>

        <Button
          href={urlReportCsv + "&route_id=" + tripschedules.oneRoute.data.feed.routes[0].route_id + "&route_short_name=" + tripschedules.oneRoute.data.feed.routes[0].route_short_name}
          className='pull-right'
          bsStyle={'success'}
          >
          <span>
            <Icon type='download'/>
            {this.messages('downloadCurrRouteCSV')}
          </span>
        </Button>
        {/*<CSVLink data={routeCSVData} filename={"reportOrariLinea"+patternsWithSchedulesData[0].routeShortName+".csv"} ref="myCSVRouteLink"/>*/}
        <Button
          style={{margin: '0 5px'}}
          className='pull-right'
          bsStyle={'warning'}
          onClick={() => scheduleReportLayoutPdf(patternsWithSchedulesData, feedInfo)}
          >
          <span>
            <Icon type='file-text'/> {this.messages('createScheduleReport')}
          </span>
        </Button>
      </div>)


    const tabelloni = allFiltersSelected && tripschedules.oneRoute.fetchStatus.fetched && patternsWithSchedulesData.length > 0 &&
      patternsWithSchedulesData.map((pattern, idx) => {

        const tripsHeaderColumns = this.getTripsHeaderColumns(pattern)
        // console.log('pattern!', pattern)
        const patternLengthFormatted = pattern.trips[0] ? ( pattern.trips[0].official_length / 1000).toLocaleString('it', {maximumFractionDigits: 1}) : 0
        const tipoServizio = pattern.trips[0]  && pattern.trips[0].trip_type === '0' ? 'urbano' : 'extraurbano'
        return (
          <div key={idx}>
            <p style={{marginTop: '35px', marginBottom: '0px'}}><b>{this.messages('pattern')}: </b>{pattern.id} - {pattern.name} </p>
              {pattern.trips[0] && pattern.trips[0].official_length &&
                <p>
                  - Lunghezza del percorso: {patternLengthFormatted} km  <br />
                  - Tipo servizio: {tipoServizio}
                </p>
              }


            <BootstrapTable
              scrollTop={ 'Bottom' }
              {...tripsTableOptions}
              headerStyle={{fontSize: 'small', textWrap: 'normal', wordWrap: 'break-word', whiteSpace: 'no-wrap'}}
              bodyStyle={{fontSize: 'small'}}
              data={pattern.pea}>
                {/*<TableHeaderColumn dataSort dataField='tripId' width='100px'>{this.messages('corsa')}</TableHeaderColumn>*/}
                <TableHeaderColumn dataField='stopSequence' isKey width='40px' row='0' rowSpan='4' headerAlign='right' dataAlign = 'right' >{this.messages('progr')}</TableHeaderColumn>
                <TableHeaderColumn dataField='stopCode'  width='80px' row='0' rowSpan='4' >{this.messages('codStop')}</TableHeaderColumn>
                <TableHeaderColumn dataField='stopName' width='360px'row='0' rowSpan='4' >{this.messages('nomeStop')}</TableHeaderColumn>
                {tripsHeaderColumns}
                {/*<TableHeaderColumn dataSort dataField='arrivalTime'>{this.messages('arrivo')}</TableHeaderColumn>
                <TableHeaderColumn dataSort dataField='departureTime'>{this.messages('partenza')}</TableHeaderColumn>*/}
            </BootstrapTable>
          </div>
      )
      })

    return (
      <div>
        <Row style={{ marginBottom: '20px' }}>
        <Col xs={12} md={2}>
            <Button
              bsStyle={'success'}
              href={urlReportCsv}>
              {/*

              onClick={this._onClick.bind(this)}
                <span>
                {
                  tripschedules.allRoutes.fetchStatus.fetching ?
                  <Icon className='fas fa-refresh fa-spin' type='refresh'/> :
                  <Icon type='download'/>
                }
                </span>
              */}
              {this.messages('downloadAllRoutesCSV')}
            </Button>
            {/*<CSVLink data={allCSVData} filename="reportOrari.csv" ref="myCSVLink"/>*/}
          </Col>
        <Col xs={12} md={2}>
            <Button
              bsStyle={'success'}
              href={urlCompanyReportCsv}>
              {this.messages('downloadCompanyReportCSV')}
            </Button>
          </Col>  
        </Row>
        <Row style={{ marginBottom: '20px' }}>
          <Col xs={12} md={6}>
            <label htmlFor='route_name'>{this.messages('route')}</label>
            <Select
              options={orderBy(routes.data, route => route.route_short_name) || []}
              labelKey={'route_name'}
              valueKey={'route_id'}
              placeholder={this.messages('selectLine')}
              value={routeFilter}
              onChange={routeFilterChange} />
          </Col>
          {routeFilter &&
            <Col xs={12} md={6}>
              <label htmlFor='pattern'>{this.messages('pattern')}</label>
              <Select
                options={patterns.data.patterns}
                valueKey={'pattern_id'}
                placeholder={this.messages('patternFilter')}
                value={patternFilter}
                onChange={patternFilterChange} />
            </Col>
          }
        </Row>
        {/*<ActiveDateTimeFilter
          onChange={timetableDateTimeFilterChange}
          version={version} />*/}

        {tripschedules.oneRoute.fetchStatus.fetching &&
          <Loading />
        }
        {tripschedules.oneRoute.fetchStatus.error &&
          <Alert bsStyle='danger'>
            {this.messages('error_fetch')}
          </Alert>
        }
        {!allFiltersSelected &&
          <Alert>
            {this.messages('select2consult')}
          </Alert>
        }
        {testalino}
        {tabelloni}
        {allFiltersSelected && tripschedules.oneRoute.fetchStatus.fetched && patternsWithSchedulesData.length === 0 &&
          <Alert bsStyle='warning'>
            {this.messages('noTripsFound')}
          </Alert>
        }
        {tripschedules.allRoutes.fetchStatus.fetched && !tripschedules.allRoutes.fetchStatus.CSV_downloaded && allCSVData.length > 0 &&
          this.downloadCSV()
        }

      </div>
    )
  }
}
