// @flow
import { connect } from 'react-redux'

import SummaryReportLayout from '../components/SummaryReportLayout'
import { fetchFeedInfo } from '../../../../gtfs/actions/feedInfo'
import { fetchAgencies } from '../../../../gtfs/actions/agencies'
import { fetchRouteReport } from '../../../../gtfs/actions/routes'
import { fetchCalendars } from '../../../../gtfs/actions/calendars'
import {
   getRouteReportDataTotals,
  } from '../../../selectors'

import type {dispatchFn, AppState} from '../../../../types/reducers'

const mapStateToProps = (state: AppState, ownProps) => {
  const {namespace} = ownProps.version
  const {gtfs} = state
  const {routes} = gtfs
  const {data, fetchStatus} = routes.routeReport
  return {
    namespace,
    fetchStatus,
    routeReportDataTotals: getRouteReportDataTotals(state),
  }
}

const mapDispatchToProps = (dispatch: dispatchFn, ownProps) => {
  const {namespace} = ownProps.version
  return {
    onComponentMount: (fetched) => {
      if ( !fetched) {
        dispatchAll(dispatch, namespace)
      }
    },
    // chiamata al cambio di versione (versionNavigator)
    versionChange: (prevNamespace) => {
      if(prevNamespace !== namespace){
        dispatchAll(dispatch, namespace)
      }   // Þ
    }
  }
}

const dispatchAll = (dispatch, namespace) => {
  dispatch(fetchFeedInfo(namespace))
  dispatch(fetchAgencies(namespace))
  dispatch(fetchCalendars(namespace))
  dispatch(fetchRouteReport(namespace))
}

const SummaryReport = connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryReportLayout)

export default SummaryReport
