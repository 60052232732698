// @flow

import React, { Component } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'

// import { formatHeadway } from '../../../../gtfs/util/stats'
import Loading from '../../../../common/components/Loading'
import SummaryNumbers from './SummaryNumbers'
import { getComponentMessages } from '../../../../common/util/config'

export type Props = {
  onComponentMount: (Props) => void,
  routeReportDataTotals: any
}

export default class SummaryReportLayout extends Component<Props> {
  messages = getComponentMessages('RoutesReportLayout')


  componentWillMount () {
    this.props.onComponentMount(this.props.fetchStatus.fetched)
  }

  componentDidUpdate(prevProps){
    this.props.versionChange(prevProps.namespace)
  }

  render () {
    const {
      fetchStatus: {
        error,
        fetching,
        fetched
      },
      routeReportDataTotals,
    } = this.props

    return (
      <div>
        {fetching &&
          <Loading />
        }
        {error &&
          <Alert bsStyle='danger'>
            {this.messages('error_fetch')}
          </Alert>
        }
        {fetched &&
          <div>
          <Row>
            <Col xs={12}>
              <p className='lead text-center'>Totali correnti</p>
            </Col>
          </Row>
            <SummaryNumbers totalNumbers={routeReportDataTotals.totaliCorrente}/>
            <hr  style={{
                color: '#ddd',
                backgroundColor: '#ddd',
                height: .5,
                borderColor : '#ddd'
            }}/>
            <Row>
              <Col xs={12}>
                <p className='lead text-center'>Totali di preventivo</p>
              </Col>
            </Row>
            <SummaryNumbers totalNumbers={routeReportDataTotals.totaliPreventivo}/>
            <hr  style={{
              color: '#ddd',
              backgroundColor: '#ddd',
              height: .5,
              borderColor : '#ddd'
              }}/>
              <Row>
                <Col xs={12}>
                  <p className='lead text-center'>Totali in proiezione</p>
                </Col>
              </Row>
            <SummaryNumbers totalNumbers={routeReportDataTotals.totaliProiezione}/>
            <br/>
          </div>
        }
      </div>
    )
  }
}
