// @flow

import React, {Component} from 'react'
import {Modal, Button} from 'react-bootstrap'

type Props = {
  onClose: () => void,
  show: boolean
}

type State = {
  showModal: boolean
}

export default class TripPatternCloseTripModal extends Component<Props, State> {
  state = {
    showModal: this.props.show
  }

  _close = () => {
    const {onClose} = this.props
    onClose && onClose()
  }

  render () {
    const {Body, Footer, Header, Title} = Modal
    return (
      <Modal
        show={this.props.show}
        onHide={this._close}>
        <Header closeButton>
          <Title>Terminazione percorso</Title>
        </Header>
        <Body>
          <div><h4>Il percorso è stato chiuso.</h4>
            </div>
        </Body>
        <Footer>

          <Button onClick={this._close}>Chiudi</Button>
        </Footer>
      </Modal>
    )
  }
}
