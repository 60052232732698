// @flow

import {createAction, type ActionType} from 'redux-actions'

import {createVoidPayloadAction, fetchGraphQL} from '../../common/actions'

import {feedInfo} from '../../gtfs/util/graphql'

import type {
  dispatchFn,
  getStateFn,
} from '../../types/reducers'

export const errorFetchingFeedInfo = createVoidPayloadAction(
  'FETCH_GRAPHQL_FEEDINFO_REJECTED'
)
export const fetchingFeedInfo = createVoidPayloadAction(
  'FETCH_GRAPHQL_FEEDINFO'
)

export const receiveFeedInfo = createAction(
  'FETCH_GRAPHQL_FEEDINFO_FULFILLED',
  (payload: Array<GtfsAgency>) => payload
)

export function fetchFeedInfo (namespace: string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    dispatch(fetchingFeedInfo())
    return dispatch(fetchGraphQL({query: feedInfo, variables: {namespace}}))
      .then(data => dispatch(receiveFeedInfo(data.feed.feed_info)))
  }
}
