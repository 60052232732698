// @flow

import { combineReducers } from 'redux'

import agencies from './agencies'
import calendars from './calendars'
import feedInfo from './feedInfo'
import filter from './filter'
import patterns from './patterns'
import routes from './routes'
import shapes from './shapes'
import stops from './stops'
import timetables from './timetables'
import trips from './trips'
import tripschedules from './tripSchedules'
import validation from './validation'

export default combineReducers({
  agencies,
  calendars,
  feedInfo,
  filter,
  patterns,
  routes,
  shapes,
  stops,
  timetables,
  trips,
  tripschedules,
  validation
})
