// @flow

import {createAction, type ActionType} from 'redux-actions'

import {createVoidPayloadAction, fetchGraphQL} from '../../common/actions'
import {
  updateArrivalDisplay,
  updatePatternFilter,
  updateRouteFilter,
  updateTimepointFilter
} from './filter'
import {tripSchedules} from '../../gtfs/util/graphql'
import {fetchPatterns} from './patterns'

import type {dispatchFn, getStateFn, TimetableData} from '../../types/reducers'


export const errorFetchingAllTripSchedules = createVoidPayloadAction(
  'FETCH_ALL_GRAPHQL_TRIPSCHEDULES_REJECTED'
)
export const fetchingAllTripSchedules = createVoidPayloadAction(
  'FETCH_ALL_GRAPHQL_TRIPSCHEDULES'
)
export const receiveAllTripSchedules = createAction(
  'FETCH_ALL_GRAPHQL_TRIPSCHEDULES_FULFILLED',
  (payload: {
    data: null
  }) => payload
)
export const setAllCsvDownloaded = createVoidPayloadAction(
  'SET_ALL_CSV_DOWNLOADED_TS_STATUS'
)

export const errorFetchingTripSchedules = createVoidPayloadAction(
  'FETCH_GRAPHQL_TRIPSCHEDULES_REJECTED'
)
export const fetchingTripSchedules = createVoidPayloadAction(
  'FETCH_GRAPHQL_TRIPSCHEDULES'
)
export const receiveTripSchedules = createAction(
  'FETCH_GRAPHQL_TRIPSCHEDULES_FULFILLED',
  (payload: {
    data: null
  }) => payload
)

export function fetchAllTripSchedules(namespace: string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    const state = getState()
    dispatch(fetchingAllTripSchedules())
    //const variables = {namespace, patternId, routeId}
    const variables = {namespace, patternId: null, routeId: null}
    return dispatch(fetchGraphQL({query: tripSchedules, variables}))
      .then(data => dispatch(receiveAllTripSchedules({data})))
  }
}

export function fetchTripSchedulesWithFilters (namespace: string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    const state = getState()
    const {
      patternFilter: patternId,
      routeFilter: routeId
    } = state.gtfs.filter
    const {patterns} = state.gtfs.patterns.data
    const pattern_ids = patterns.map(p => p.pattern_id)
    const patternVariable = patternId || pattern_ids
    if (!routeId || !patternVariable) {
      return dispatch(receiveTripSchedules({ data: null }))
    }
    dispatch(fetchingTripSchedules())
    //const variables = {namespace, patternId, routeId}
    const variables = {namespace, patternId: patternVariable, routeId}
    return dispatch(fetchGraphQL({query: tripSchedules, variables}))
      .then(data => dispatch(receiveTripSchedules({data})))
  }
}

export function tripsPatternFilterChange (feedId: string, patternId: ?string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    dispatch(updatePatternFilter(patternId))
    dispatch(fetchTripSchedulesWithFilters(feedId))
  }
}

export function tripsRouteFilterChange (feedId: string, routeId: ?string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    dispatch(updateRouteFilter(routeId))
    dispatch(updatePatternFilter(null))
    dispatch(fetchPatterns(feedId, routeId))
    .then( () =>  {dispatch(fetchTripSchedulesWithFilters(feedId))})
  }
}
