// @flow

import React, { Component } from 'react'
import moment from 'moment'

import Loading from '../../../common/components/Loading'
import toSentenceCase from '../../../common/util/text'
import {getChartMax, getChartPeriod} from '../../util'
import { getComponentMessages } from '../../../common/util/config'

type Props = {
  //validationResult: ValidationResult
  namespace: any
}

const COLORS = {
  Feriale: '#8da0cb',
  Sabato: '#66c2a5',
  Domenica: '#fc8d62'
}
const maxDaysToShow = 500

export default class KmChart extends Component<Props> {

  messages = getComponentMessages('TripsChart')

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      isLoaded: false,
      graphs: {}
    }
  }

  componentDidMount() {
    this.updateGraph()
  }

  componentDidUpdate(prevProps){
    if(this.props.namespace !== prevProps.namespace) // Check if it's a new namespace
    {
      this.updateGraph()
    }
  }

  updateGraph(){
    fetch(`${process.env.PUSA_URL}/utility/graphs?schema=${this.props.namespace}`)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            graphs: result
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }


  render () {
    //5t const {validationResult} = this.props
    const validationResult = this.state.graphs
    if (!validationResult || !validationResult.dailyKmCounts) {
      return <Loading />
    }
    const {dailyKmCounts, firstCalendarDate} = validationResult
    const firstDate = moment(firstCalendarDate)
    const data = dailyKmCounts
      .slice(0, maxDaysToShow)
      .map((count, index) => [firstDate.clone().add(index, 'days'), count])
    const graphHeight = 300
    const spacing = 8
    const leftMargin = 50
    const rightMargin = 50
    const bottomMargin = 75
    const svgWidth = leftMargin + rightMargin + (data.length * spacing)
    const svgHeight = graphHeight + bottomMargin
    const maxKm = Math.max.apply(Math, data.map(d => d[1]))
    const yAxisMax = getChartMax(maxKm)
    const yAxisPeriod = getChartPeriod(maxKm)
    const yAxisLabels = []
    for (var i = 0; i <= yAxisMax; i += yAxisPeriod) {
      yAxisLabels.push(i)
    }
    const curtailed = maxDaysToShow < dailyKmCounts.length
    return (
      <div
        className='text-center'
        style={{
          width: '100%',
          height: `${svgHeight}px`,
          overflowX: 'scroll',
          overflowY: 'hidden',
          border: '#ddd'
        }}>
        <svg style={{width: svgWidth, height: `${svgHeight}px`, paddingTop: '5px'}}>
          {yAxisLabels.map((l, index) => {
            const y = graphHeight - ((l / yAxisMax) * graphHeight)
              return <g key={index}>
                <line
                  x1={0} y1={y}
                  x2={svgWidth} y2={y}
                  stroke='gray'
                  strokeWidth={1}
                />
                <text x={0} y={y - 2} fill='gray'>
                  {l}
                </text>
              </g>
          })}
          {data.map((d, index) => {
            const dow = d[0].day()
            const dateString = d[0].format('YYYY-MM-DD')
            const x = leftMargin + (spacing / 2) + (index * spacing)

            // generate the bar for this date
            return (
              <g key={index}>
                <title>{dateString}: {d[1]} km</title>
                <line
                  x1={x} y1={graphHeight - ((d[1] / yAxisMax) * graphHeight)}
                  x2={x} y2={graphHeight}
                  title={`${dateString}: ${d[1]} trips`}
                  stroke={dow === 0
                    ? COLORS.Domenica
                    : dow === 6
                      ? COLORS.Sabato
                      : COLORS.Feriale
                  }
                  strokeWidth={7} />
                {/* label x-axis with dates every 14 days */}
                {index % 14 === 0
                  ? <g>
                    <line x1={x} y1={graphHeight} x2={x} y2={graphHeight + 12} stroke='black' />
                    <text x={x - 35} y={graphHeight + 26} fill='black'>
                      {dateString}
                    </text>
                  </g>
                  : null
                }
              </g>
            )
          })}
          {/* Add baseline to chart */}
          <line
            x1={0}
            y1={graphHeight}
            x2={svgWidth}
            y2={graphHeight}
            stroke='black'
            strokeWidth={2} />
          {/* Add legend for bar colors */}
          {Object.keys(COLORS).map((k, i) => (
            <g key={k}>
              <rect
                x={100 * i - 5}
                y={graphHeight + 30}
                width='80'
                height='18'
                fill={COLORS[k]} />
              <text x={100 * i} y={graphHeight + 43} fill='black'>
                {toSentenceCase(k)}
              </text>
            </g>
          ))}
          {curtailed &&
            <text x={100 * Object.keys(COLORS).length} y={graphHeight + 43}>
              {this.messages('onlyFirst500Days')}
            </text>
          }
        </svg>
      </div>
    )
  }
}
