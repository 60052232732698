// @flow

import { connect } from 'react-redux'
import ScheduleReportLayout from '../components/ScheduleReportLayout'
import { fetchFeedInfo } from '../../../../gtfs/actions/feedInfo'
import { fetchRoutes } from '../../../../gtfs/actions/routes'
import { fetchAgencies } from '../../../../gtfs/actions/agencies'
import { fetchCalendars } from '../../../../gtfs/actions/calendars'
import {
  fetchTripSchedulesWithFilters,
  tripsPatternFilterChange,
  tripsRouteFilterChange,
  fetchAllTripSchedules,
  setAllCsvDownloaded} from '../../../../gtfs/actions/tripSchedules'
import {getPatternsWithSchedulesData, getTripSchedulesCSVData, getCurrentRouteTripSchedulesCSVData} from '../../../selectors'

import type {
  dispatchFn,
  AppState,
  RouteListItem,
  ValidationPattern
} from '../../../../types/reducers'

const mapStateToProps = (state: AppState, ownProps) => {
  const {gtfs} = state
  const {
    filter,
    patterns,
    routes,
    tripschedules
  } = gtfs
  return {
    filter,
    patterns,
    routes: routes.allRoutes,
    tripschedules,
    patternsWithSchedulesData: getPatternsWithSchedulesData(state),
    allCSVData: getTripSchedulesCSVData(state),
    routeCSVData: getCurrentRouteTripSchedulesCSVData(state),
    feedInfo: state.gtfs.feedInfo.data.feed_info

  }
}

const mapDispatchToProps = (dispatch: dispatchFn, ownProps): any => {
  const {namespace} = ownProps.version
  return {
    onComponentMount: (initialProps) => {
      if (!initialProps.routes.fetchStatus.fetched) {
        dispatch(fetchFeedInfo(namespace))
        dispatch(fetchAgencies(namespace))
        dispatch(fetchRoutes(namespace))
        dispatch(fetchCalendars(namespace))
      } else if (
        initialProps.routes.fetchStatus.fetched &&
        initialProps.patterns.fetchStatus.fetched &&
        !initialProps.tripschedules.oneRoute.fetchStatus.fetched
      ) {
        dispatch(fetchTripSchedulesWithFilters(namespace))
      }
    },
    routeFilterChange: (route: ?RouteListItem) => {
      dispatch(tripsRouteFilterChange(namespace, route && route.route_id))
    },
    patternFilterChange: (pattern: ?ValidationPattern) => {
      dispatch(tripsPatternFilterChange(namespace, pattern && pattern.pattern_id))
    },
    getAllPatternInformations: () => {
      dispatch(fetchAllTripSchedules(namespace))
    },
    setAllcsvStatus: () => {
      dispatch(setAllCsvDownloaded(namespace))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleReportLayout)
