// @flow

import {createAction, type ActionType} from 'redux-actions'

import {createVoidPayloadAction, fetchGraphQL} from '../../common/actions'

import {agencies} from '../../gtfs/util/graphql'

import type {
  dispatchFn,
  getStateFn,
} from '../../types/reducers'

export const errorFetchingAgencies = createVoidPayloadAction(
  'FETCH_GRAPHQL_AGENCIES_REJECTED'
)
export const fetchingAgencies = createVoidPayloadAction(
  'FETCH_GRAPHQL_AGENCIES'
)

export const receiveAgencies = createAction(
  'FETCH_GRAPHQL_AGENCIES_FULFILLED',
  (payload: Array<GtfsAgency>) => payload
)

export function fetchAgencies (namespace: string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    dispatch(fetchingAgencies())
    return dispatch(fetchGraphQL({query: agencies, variables: {namespace}}))
      .then(data => dispatch(receiveAgencies(data.feed.agency)))
  }
}
