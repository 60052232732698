// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, { Component } from 'react'
import { Alert, Button, ControlLabel, FormControl, Modal } from 'react-bootstrap'

import * as tripPatternActions from '../../actions/tripPattern'
import type { GtfsStop, Pattern } from '../../../types'

type Props = {
  activePattern: Pattern,
  normalizeStopTimes: typeof tripPatternActions.normalizeStopTimes,
  onClose: any,
  show: boolean,
  stops: Array<GtfsStop>
}

type State = { patternStopIndex: number, show: boolean }

export default class NormalizeStopTimesModal extends Component<Props, State> {
  state = {
    patternStopIndex: 0, // default to zeroth pattern stop
    show: false
  }

  _onClickNormalize = () => {
    const { activePattern, normalizeStopTimes } = this.props
    normalizeStopTimes(activePattern.id, this.state.patternStopIndex)
  }

  _onChangeStop = (evt: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({patternStopIndex: +evt.target.value})
  }

  _onClose = () => {
    this.setState({ show: false })
    this.props.onClose()
  }

  render () {
    const { Body, Footer, Header, Title } = Modal
    const { activePattern, stops } = this.props
    return (
      <Modal show={this.props.show || this.state.show} onHide={this._onClose}>
        <Header>
          <Title>Vuoi aggiornare gli orari delle corse?</Title>
        </Header>
        <Body>
          <p>
            Saranno sovrascritti gli orari di arrivo e partenza per TUTTE le corse di questo percorso, 
            in modo da rispettare i tempi di viaggio e di sosta impostati a livello di percorso.
          </p>
          <ControlLabel>Seleziona la prima fermata del percorso da cui applicare l'aggiornamento:</ControlLabel>
          <FormControl
            value={this.state.patternStopIndex}
            componentClass='select'
            onChange={this._onChangeStop}>
            {activePattern.patternStops.map((patternStop, index) => {
              const stop = stops.find(s => s.stop_id === patternStop.stopId)
              if (!stop) return null
              return (
                <option
                  value={index}
                  key={index}>
                  {index + 1} - {stop.stop_name}
                </option>
              )
            }
            )}
          </FormControl>
          <br />
          <Alert bsStyle='warning'>
            {this.state.patternStopIndex === 0
              // TODO: figure out how yml messages with html tags can be rendered
              // correctly.
              ? <span>
                Gli orari delle corse saranno aggiornati per TUTTE le fermate del percorso.
              </span>
              : <span>
                Saranno aggiornati gli orari dalla fermata n. {this.state.patternStopIndex + 1}{' '}
                fino all'ultima fermata (non ci saranno modifiche per quelle precedenti).
              </span>
            }
          </Alert>
          <Alert bsStyle='info'>
            <h5><Icon type='info-circle' /> Nota</h5>
            <small>
              Puoi utilizzare questo strumento per aggiornare automaticamente tutti gli orari di tutte le corse quando, 
              ad esempio, aggiungi o rimuovi fermate dal percorso. <b>Fai attenzione:</b> questa operazione sovrascriverà anche gli orari modificati a mano sull'editor corse.
            </small>
          </Alert>
        </Body>
        <Footer>
          <Button
            bsStyle='primary'
            onClick={this._onClickNormalize}
          >
            Aggiorna orari corse
          </Button>
          <Button
            onClick={this._onClose}>
            Chiudi
          </Button>
        </Footer>
      </Modal>
    )
  }
}
