// @flow

import {createAction, type ActionType} from 'redux-actions'

import {createVoidPayloadAction, fetchGraphQL} from '../../common/actions'
import {
  updateArrivalDisplay,
  updatePatternFilter,
  updateRouteFilter,
  updateTimepointFilter
} from './filter'
import {trips} from '../../gtfs/util/graphql'
import {fetchPatterns} from './patterns'

import type {dispatchFn, getStateFn, TimetableData} from '../../types/reducers'

export const errorFetchingAllTrips = createVoidPayloadAction(
  'FETCH_ALL_GRAPHQL_TRIPS_REJECTED'
)
export const fetchingAllTrips = createVoidPayloadAction(
  'FETCH_ALL_GRAPHQL_TRIPS'
)
export const receiveAllTrips = createAction(
  'FETCH_ALL_GRAPHQL_TRIPS_FULFILLED',
  (payload: {
    data: null
  }) => payload
)
export const setAllCsvDownloaded = createVoidPayloadAction(
  'SET_ALL_CSV_DOWNLOADED_STATUS'
)

export const errorFetchingTrips = createVoidPayloadAction(
  'FETCH_GRAPHQL_TRIPS_REJECTED'
)
export const fetchingTrips = createVoidPayloadAction(
  'FETCH_GRAPHQL_TRIPS'
)
export const receiveTrips = createAction(
  'FETCH_GRAPHQL_TRIPS_FULFILLED',
  (payload: {
    data: null
  }) => payload
)

export function fetchAllTrips(namespace: string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    const state = getState()
    dispatch(fetchingAllTrips())
    const variables = {namespace, patternId: null}
    return dispatch(fetchGraphQL({query: trips, variables}))
      .then(data => dispatch(receiveAllTrips({data})))
  }
}

export function fetchTripsWithFilters (namespace: string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    const state = getState()
    const {
      patternFilter: patternId,
      routeFilter: routeId
    } = state.gtfs.filter
    const {patterns} = state.gtfs.patterns.data
    const pattern_ids = patterns.map(p => p.pattern_id)
    const patternVariable = patternId || pattern_ids

    if (!routeId || !patternVariable) {
      return dispatch(receiveTrips({ data: null }))
    }
    dispatch(fetchingTrips())
    // const variables = {namespace, patternId, routeId}
    const variables = {namespace, patternId: patternVariable, routeId}
    return dispatch(fetchGraphQL({query: trips, variables}))
      .then(data => dispatch(receiveTrips({data})))
  }
}

export function tripsPatternFilterChange (feedId: string, patternId: ?string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    dispatch(updatePatternFilter(patternId))
    dispatch(fetchTripsWithFilters(feedId))
  }
}

export function tripsRouteFilterChange (feedId: string, routeId: ?string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    dispatch(updateRouteFilter(routeId))
    dispatch(updatePatternFilter(null))
    dispatch(fetchPatterns(feedId, routeId))
    .then( () => {dispatch(fetchTripsWithFilters(feedId))})
    //dispatch(fetchTripsWithFilters(feedId))
  }
}
