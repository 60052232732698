// @flow

import type {Action} from '../../types/actions'
import update from 'react-addons-update'

export const defaultState = {
  allRoutes: {
    fetchStatus: {
      fetched: false,
      fetching: false,
      error: false,
      CSV_downloaded: false
    },
    data: null
  },
  oneRoute: {
    fetchStatus: {
      fetched: false,
      fetching: false,
      error: false
    },
    data: null
  }
}

export default function reducer (
  state = defaultState,
  action: Action
) {
  switch (action.type) {
    case 'SET_ACTIVE_FEEDVERSION':
      return defaultState
    case 'FETCH_ALL_GRAPHQL_TRIPSCHEDULES':
      return update(state, {
        allRoutes: {
          $set: {
            fetchStatus: {
              fetched: false,
              fetching: true,
              error: false,
              CSV_downloaded: false
            },
            data: null
          }
        }
      })
    case 'FETCH_ALL_GRAPHQL_TRIPSCHEDULES_REJECTED':
      return update(state, {
        allRoutes: {
          $set: {
            fetchStatus: {
              fetched: false,
              fetching: false,
              error: true,
              CSV_downloaded: false
            },
            data: null
          }
        }
      })
    case 'FETCH_ALL_GRAPHQL_TRIPSCHEDULES_FULFILLED':
      const {data} = action.payload
      return update(state, {
        allRoutes: {
          $set: {
            fetchStatus: {
              fetched: true,
              fetching: false,
              error: false,
              CSV_downloaded: false
            },
            data
          }
        }
      })
    case 'SET_ALL_CSV_DOWNLOADED_TS_STATUS':
    return update(state, {
      allRoutes: {
        fetchStatus: {
          $set: {
            fetched: true,
            fetching: false,
            error: false,
            CSV_downloaded: true
          }
        }
      }
    })
    case 'FETCH_GRAPHQL_TRIPSCHEDULES':
      return update(state, {
        oneRoute: {
          $set: {
            fetchStatus: {
              fetched: false,
              fetching: true,
              error: false
            },
            data: null
          }
        }
      })
    case 'FETCH_GRAPHQL_TRIPSCHEDULES_REJECTED':
      return update(state, {
        oneRoute: {
          $set: {
            fetchStatus: {
              fetched: false,
              fetching: false,
              error: true
            },
            data: null
          }
        }
      })
    case 'FETCH_GRAPHQL_TRIPSCHEDULES_FULFILLED':
      return update(state, {
        oneRoute: {
          $set: {
            fetchStatus: {
              fetched: true,
              fetching: false,
              error: false
            },
            data: action.payload.data
          }
        }
      })
    default:
      return state
  }
}
