// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, {Component} from 'react'
import moment from 'moment'
import { Link } from 'react-router'

import type {RecentActivity} from '../../types'
import type {ManagerUserState} from '../../types/reducers'
import {getConfigProperty} from '../../common/util/config'
import {formatTimestamp, fromNow} from '../../common/util/date-time' //5t


const dateFormat = getConfigProperty('application.date_format') || 'MMM Do YYYY'
const timeFormat = getConfigProperty('application.time_format') || 'h:MMa'

const getPrimaryURL = (feedSourceId: string, versionIndex: number) => versionIndex
  ? getVersionURL(feedSourceId, versionIndex)
  : getFeedSourceURL(feedSourceId)

const getFeedSourceURL = (id: string) => `/feed/${id}`

const getVersionURL = (feedSourceId: string, versionIndex: number) =>
  `/feed/${feedSourceId}/version/${versionIndex}`

const getIconType = (type: string) => {
  switch (type) {
    case 'feed-commented-on':
    case 'version-commented-on':
      return 'comment'
    case 'feed-created':
      return 'bus'
    case 'version-created':
      return 'list'
    default:
      return null
  }
}

type Props = {
  currentUser: ManagerUserState,
  item: RecentActivity
}

export default class RecentActivityBlock extends Component<Props> {
  render () {
    const { item, currentUser } = this.props
    const {date, type} = item
    const activityDate = moment(date)
    return (
      <div className='recent-activity-container'>
        <div className='recent-activity-icon'>
          <Icon type={getIconType(type)} />
        </div>
        <div className='recent-activity-inner'>
          <div
            className='recent-activity-date'
            title={activityDate.format(dateFormat + ', ' + timeFormat)}>
            {/* 5t*/}
            {fromNow(activityDate)}
          </div>
          <MetaBlock item={item} currentUser={currentUser} />
          <PrimaryBlock item={item} />
        </div>
      </div>
    )
  }
}

type MetaProps = {
  currentUser: ManagerUserState,
  item: RecentActivity
}

class MetaBlock extends Component<MetaProps> {
  render () {
    const {currentUser, item} = this.props
    // TODO: Refactor below switch to share more of the common JSX with certain
    // items (e.g., links, text) conditionally dependent on recent activity type.
    const {
      feedVersionIndex,
      feedVersionName,
      feedSourceId,
      type,
      feedSourceName,
      userName
    } = item
    switch (type) {
      case 'feed-commented-on':
      case 'version-commented-on':
        return (
          <div>
            {currentUser.profile && currentUser.profile.name === userName
              ? 'Hai creato una nota sul feed '
              : <span><b>{userName}</b> ha creato una nota sul feed </span>
            }
            {/*' commented on feed '*/}
            <Link to={getFeedSourceURL(feedSourceId)}>
              <b>{feedSourceName}</b>
            </Link>
            {feedVersionIndex && feedSourceName && (
              <span>
                {', version '}
                <Link to={getVersionURL(feedSourceId, feedVersionIndex)}>
                  <b>{feedVersionName}</b>
                </Link>
              </span>
            )}
            {`:`}
          </div>
        )
      default:
        return null
    }
  }
}

type PrimaryProps = {
  item: RecentActivity
}

class PrimaryBlock extends Component<PrimaryProps> {
  render () {
    const {item} = this.props
    const {
      feedVersionIndex,
      feedVersionName,
      feedSourceId,
      type,
      feedSourceName,
      projectName,
      projectId,
      body
    } = item
    const primaryURL = getPrimaryURL(feedSourceId, feedVersionIndex)
    const primaryName = feedVersionIndex
      ? feedVersionName
      : feedSourceName
    switch (type) {
      case 'feed-commented-on':
      case 'version-commented-on':
        return (
          <div>
            <Link to={`${primaryURL}/comments`}>
              <div className='recent-activity-comment'><i>{body}</i></div>
            </Link>
          </div>
        )
      case 'version-created':
        return (
          <div>
            {`Creata versione ${feedVersionIndex} `}
            <Link to={primaryURL}>
              <b>{primaryName}</b>
            </Link>
            <span>
              {' per il feed '}
              <Link to={getFeedSourceURL(feedSourceId)}>
                <b>{feedSourceName}</b>
              </Link>
            </span>
          </div>
        )
      case 'feed-created':
        return (
          <div>
            {`Nuovo feed `}
            <Link to={primaryURL}>
              <b>{primaryName}</b>
            </Link>
            <span>
              {' creato nel progetto '}
              <Link to={`/project/${projectId}`}>
                <b>{projectName}</b>
              </Link>
            </span>
          </div>
        )
      default:
        return null
    }
  }
}
