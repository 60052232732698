// @flow

import React, {Component} from 'react'
import VirtualizedSelect from 'react-virtualized-select'
import createFilterOptions from 'react-select-fast-filter-options'

import {getEntityName} from '../util/gtfs'
import { getComponentMessages } from '../../common/util/config'
import {componentToText} from '../util/objects'

import type {Entity} from '../../types'

export type EntityOption = {
  entity: Entity,
  label: string,
  value: string
}

type Props = {
  clearable?: boolean,
  component: string,
  entities: Array<any>,
  entityKey: string,
  onChange: any => void,
  optionRenderer?: Function,
  style?: {[string]: number | string},
  value?: any
}

type State = {
  options: Array<any>,
  filterOptions: any,
  value: any
}

export default class VirtualizedEntitySelect extends Component<Props, State> {
  messages = getComponentMessages('VirtualizedEntitySelect')
  static defaultProps = {
    clearable: true,
    entityKey: 'id'
  }

  componentWillMount () {
    const { entities } = this.props
    let options = []
    options = entities
      .filter(e => {
        if (e.id < 0) {
          console.warn(`Entity has a negative id, which indicates an unsaved entity that should not be selectable. Filtering out of selector.`, e)
          return false
        } else return e
      })
      .map(this._entityToOption)

    const filterOptions = createFilterOptions({
      options
    })

    this.setState({
      value: this.props.value,
      options: options,
      filterOptions: filterOptions
    })
  }

  /**
   * This component can hold a large number of options, so its
   * shouldComponentUpdate method checks only for changes in state (i.e., the
   * selected value) and a change in length of its entities.
   */
  shouldComponentUpdate (nextProps, nextState) {
    if (nextState.value !== this.state.value || this.state.options.length !== nextState.options.length) {
      return true
    }
    const nextEntities = nextProps.entities || []
    const currentEntities = this.props.entities || []
    if (nextEntities.length !== currentEntities.length) {
      console.log('entities length changed', nextEntities, currentEntities)
      return true
    }
    return false
  }

  componentWillReceiveProps (nextProps: Props) {
    if (this.state.value !== nextProps.value && typeof this.props.value !== 'undefined') {
      this.setState({value: nextProps.value})
    }
  }

  _onChange = (value: any) => {
    const {onChange} = this.props
    this.setState({value})
    onChange && onChange(value)
  }

  _entityToOption = (entity: any) => {
    const {entityKey} = this.props
    return {
      value: entity[entityKey],
      label: `${getEntityName(entity)}` || this.messages('unnamed'),
      entity
    }
  }

  render () {
    const {
      clearable,
      component,
      entities,
      optionRenderer,
      style
    } = this.props
    const {value} = this.state
    let disabled = false
    //componentToText(activeComponent)
    let placeholder = `seleziona ${componentToText(component)}...`
    let options = []
    return (
      <VirtualizedSelect
        placeholder={placeholder}
        options={this.state.options}
        filterOptions={this.state.filterOptions}
        searchable
        disabled={disabled}
        clearable={clearable}
        onChange={this._onChange}
        value={value}
        style={style}
        optionRenderer={optionRenderer} />
    )
  }
}
