// @flow

import React, {Component} from 'react'
import {Modal, Button} from 'react-bootstrap'
import { getComponentMessages } from '../../common/util/config'

type Props = {
  onClose: () => void,
  show: boolean
}

type State = {
  showModal: boolean
}

export default class CalendarHelpModal extends Component<Props, State> {
  messages = getComponentMessages('CalendarHelpModal')
  state = {
    showModal: this.props.show
  }

  _close = () => {
    const {onClose} = this.props
    onClose && onClose()
  }

  render () {
    const {Body, Footer, Header, Title} = Modal
    const STYLE = {
        weeklyDefaultStyle: {
            backgroundColor: '#9fc68f',
            paddingLeft: '2.7px',
            width: '20px',
            height: '20px',
            fontSize: 'small',
            float: 'left'
        },
        daysAddedStyle: {
            float: 'left',
            paddingLeft: '2.7px',
            width: '22px',
            height: '24px',
            fontSize: 'small',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width='20' height='20'><circle cx='10' cy='10' r='9' stroke='forestgreen' stroke-width='2' fill='none'/></svg>`
        },
        daysExcludedStyle: {
            float: 'left',
            backgroundColor: '#9fc68f',
            paddingLeft: '2.7px',
            width: '20px',
            height: '20px',
            fontSize: 'small',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\' width='20' height='20'><circle cx='10' cy='10' r='9' stroke='rgb(198, 13, 3)'  stroke-width='2' fill='none'/></svg>`
        },
        lipadding: {
          paddingTop: '10px',
        },
        floating: {
          float: 'left'
        },
        padding:{
          padding: '4px'
        }
    };
    return (
      <Modal
        show={this.props.show}
        onHide={this._close}>
        <Header closeButton>
          <Title>{this.messages('title')}</Title>
        </Header>
        <Body>
          <div><div>
            <div  tabIndex="-1" role="gridcell" aria-label="Wed Jun 20 2018" aria-disabled="false" aria-selected="false" style={STYLE.floating}>11</div>
            </div>
            <span style={STYLE.padding}>: {this.messages(`no_service`)}</span>
          </div>
            <div style={STYLE.lipadding}><div>
              <div  tabIndex="-1" role="gridcell" aria-label="Wed Jun 20 2018" aria-disabled="false" aria-selected="false" style={STYLE.weeklyDefaultStyle}>20</div>
              </div>
              <span style={STYLE.padding}>: {this.messages(`weekly_default`)}</span>
            </div>
            <div style={STYLE.lipadding}>
              <div  tabIndex="-1" role="gridcell" aria-label="Wed Jun 20 2018" aria-disabled="false" aria-selected="false" style={STYLE.daysExcludedStyle}>25</div>
              <span style={STYLE.padding}>: {this.messages(`days_excluded`)}</span>
            </div>
            <div style={STYLE.lipadding}>
              <div  tabIndex="-1" role="gridcell" aria-label="Wed Jun 20 2018" aria-disabled="false" aria-selected="false" style={STYLE.daysAddedStyle}>30</div>
              <span style={STYLE.padding}>: {this.messages(`days_added`)}</span>
            </div>
        </Body>
        <Footer>

          <Button onClick={this._close}>{this.messages(`close`)}</Button>
        </Footer>
      </Modal>
    )
  }
}
