// @flow

import Icon from '@conveyal/woonerf/components/icon'
import React, { Component } from 'react'
import { Alert, Col, ControlLabel, FormControl, Row } from 'react-bootstrap'
import moment from 'moment'
//import DateTimeField from 'react-bootstrap-datetimepicker'
import Datetime from 'react-datetime';
import { getComponentMessages } from '../../../../common/util/config'
import { getConfigProperty } from '../../../../common/util/config'

import * as filterActions from '../../../../gtfs/actions/filter'
import type {Props as ContainerProps} from '../containers/ActiveDateTimeFilter'
import type {DateTimeFilter as DateTimeFilterState} from '../../../../types/reducers'

type Props = ContainerProps & {
  dateTime: DateTimeFilterState,
  updateDateTimeFilter: typeof filterActions.updateDateTimeFilter
}

const timeOptions = [
  {
    label: 'Giornata completa (00:00 - 04:00 giorno seguente)',
    from: 0,
    to: 60 * 60 * 28
  },
  {
    label: 'Ora di punta mattino (06:00 - 09:00)',
    from: 60 * 60 * 6,
    to: 60 * 60 * 9
  },
  {
    label: 'Ora di punta mezzogiorno (11:00 - 14:00)',
    from: 60 * 60 * 11,
    to: 60 * 60 * 14
  },
  {
    label: 'Ora di punta pomeriggio (16:00 - 19:00)',
    from: 60 * 60 * 16,
    to: 60 * 60 * 19
  },
  {
    label: 'Servizio serale (19:00 - 23:00)',
    from: 60 * 60 * 19,
    to: 60 * 60 * 23
    //originale 19-22
  },
  {
    label: '24 ore (00:00 - 23:59)',
    from: 0,
    to: (60 * 60 * 24) - 1 // 86399
  }
]

export default class DateTimeFilter extends Component<Props> {
  //5t
  constructor() {
    super()
    this.state = {
      date_format: getConfigProperty('application.date_format')
    }
  }
  /*5t _onChangeDateTime = (millis: number) => {
    const date = moment(+millis).format('YYYYMMDD')
    this.props.updateDateTimeFilter({date})
    this.props.onChange && this.props.onChange({date})
  }*/


  _onDateChange = (momentOrString: any) => {
    // console.log('momentOrString', momentOrString)
  //  const {updateActiveGtfsEntity, activeEntity, activeComponent, field} = this.props
    const validFormats = [ 'D/M/YYYY', 'D.M.YYYY']
    var date
    if (typeof momentOrString === 'string') {
      const momObj = moment(momentOrString, validFormats, 'it', true)
      date = momObj.isValid() ? momObj.format('YYYYMMDD') : null
    } else if (moment.isMoment(momentOrString)){
      date = momentOrString.isValid() ? momentOrString.format('YYYYMMDD') : null
    } else {
      date = null
    }
    //console.log('date', date)
    this.props.updateDateTimeFilter({date})
    this.props.onChange && this.props.onChange({date})
  }

  _onChangeTimeRange = (evt: SyntheticInputEvent<HTMLInputElement>) => {
    const fromTo = evt.target.value.split('-')
    const from = +fromTo[0]
    const to = +fromTo[1]
    this.props.updateDateTimeFilter({from, to})
    this.props.onChange && this.props.onChange({from, to})
  }

  render () {
    const {dateTime, hideDateTimeField, version} = this.props
    /* 5t const dateTimeProps = {
      dateTime: dateTime.date ? +moment(dateTime.date, 'YYYY-MM-DD') : +moment(),
      defaultText: !dateTime.date ? 'Please select a date' : undefined,
      mode: 'date',
      onChange: this._onChangeDateTime
    }*/
    require('moment/locale/it') // 5t Now react-datetime will be in italian
    const dateTimeProps = {}
    dateTimeProps.timeFormat = false  // disable timePicker
    /* 5t dateTimeProps.mode = 'date'
       dateTimeProps.dateTime = currentValue ? +moment(currentValue) : defaultValue
    */
    dateTimeProps.value = dateTime ? moment(dateTime.date) : null
    dateTimeProps.onChange = this._onDateChange
    dateTimeProps.dateFormat =  this.state.date_format  //'YYYY-MM-DD'
    dateTimeProps.closeOnSelect = true
    const inputProps = {}
    if (!dateTime) {
      inputProps.placeholder = `${this.messages('select_date')}   [${this.state.date_format}]`
    }
    inputProps.type = 'search'
    // inputProps.clearable = true
    dateTimeProps.inputProps = inputProps


    const validDate =
      version &&
      moment(dateTime.date).isBetween(
        version.validationSummary.startDate,
        version.validationSummary.endDate,
        // Set units undefined
        undefined,
        // [] indicates that check is inclusive of dates
        '[]'
      )
    return (
      <div>
        <Row>
          <Col xs={12} md={4} style={{paddingTop: '10px'}}>
            <ControlLabel>Data:</ControlLabel>
            <Datetime {...dateTimeProps}  />
            {/* 5t <DateTimeField {...dateTimeProps} /> */}
          </Col>
          {!hideDateTimeField &&
            <Col xs={12} md={4} style={{paddingTop: '10px'}}>
              <ControlLabel>Fascia oraria:</ControlLabel>
              <FormControl
                componentClass='select'
                placeholder='Seleziona un range temporale'
                value={`${dateTime.from}-${dateTime.to}`}
                onChange={this._onChangeTimeRange}>
                {timeOptions.map((t, i) => {
                  return <option key={i} value={`${t.from}-${t.to}`}>{t.label}</option>
                })}
              </FormControl>
            </Col>
          }
        </Row>
        {!validDate
          ? <Row>
            <Col xs={12} style={{paddingTop: '10px'}}>
              <Alert bsStyle='danger'>
                <Icon type='exclamation-triangle' />
                <span><strong>Attenzione!</strong> La data scelta è fuori dal range di validità del feed.</span>
              </Alert>
            </Col>
          </Row>
          : null
        }
      </div>
    )
  }
}
