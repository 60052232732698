// @flow

import React, {Component} from 'react'
import { shallowEqual } from 'react-pure-render'
import Select from 'react-select'

type Option = {label: string, value: string}

type Props = {
  clearable?: boolean,
  onChange: Option => void,
  placeholder?: string,
  value: ?string
}

type State = {value: ?(string | Option)}

export default class TripTypeSelect extends Component<Props, State> {

  componentWillMount () {
    this.setState({
      value: this.props.value
    })
  }

  componentWillReceiveProps (nextProps: Props) {
    if (!shallowEqual(nextProps.value, this.props.value)) {
      this.setState({value: nextProps.value})
    }
  }

  onChange = (value: Option) => {
    const {onChange} = this.props
    this.setState({value})
    onChange && onChange(value)
  }

  render () {


    const tripTypes = [
      'Urbano',
      'Extraurbano',
      // 'Tramviario',
      // 'Metro'
    ]
    const {clearable, placeholder} = this.props
    const options = tripTypes.map((tt, idx) => {
      return {label: tt, value: idx}
    })
    return (
      <Select

        style={{marginBottom: '20px'}}
        filterOptions
        clearable={clearable}
        placeholder={placeholder || 'seleziona il tipo di percorso'}
        options={options}
        value={this.state.value}
        onChange={this.onChange} />
    )
  }
}
