// @flow

import Icon from '@conveyal/woonerf/components/icon'
import clone from 'lodash/cloneDeep'
import React, {Component} from 'react'
import {OverlayTrigger, Tooltip, ButtonGroup, Button} from 'react-bootstrap'
import Datetime from 'react-datetime'; //5t

import {ENTITY} from '../../constants'
import {generateUID} from '../../../common/util/util'
import {entityIsNew} from '../../util/objects'
import { getConfigProperty } from '../../../common/util/config'
import TripPatternCloseTripModal from './TripPatternCloseTripModal' //5t

import type {Props} from './TripPatternList'

export default class TripPatternListControls extends Component<Props> {
  state = {cloningDate: new Date(), closingDate: new Date(), showCloningDateControl: false, showCloseTripModal: false}

  _onClickClonePattern = () => {
    const {activePattern, cloneGtfsEntity, feedSource} = this.props
    cloneGtfsEntity(feedSource.id, 'trippattern', activePattern.id, true)
  }

  _onClickVersionatePatternShow = () => {
    this.setState({showCloningDateControl: !this.state.showCloningDateControl, showClosingDateControl: null})
    console.log('Apri controlli di versionamento')
  }
  _onClickClosePatternShow = () => {
    this.setState({showClosingDateControl: !this.state.showClosingDateControl, showCloningDateControl: null})
    console.log('Apri controlli di chiusura')
  }

  _onClickVersionatePattern = () => {
    const {activePattern, cloneGtfsEntity, feedSource, clonePatternWithTrips} = this.props
    console.log('clona percorso con corse')
    clonePatternWithTrips(feedSource.id, activePattern.id, this.state.cloningDate, activePattern.patternId)
  }

  _onClickClosePattern = () => {
    const {activePattern, cloneGtfsEntity, feedSource, closePatternWithTrips} = this.props
    console.log('termina corse percorso')
    closePatternWithTrips(feedSource.id, activePattern.id, this.state.closingDate, activePattern.patternId)
    this._showCloseTripModal()
  }

  _handleDateChange = (cloningDate)  => {
    return this.setState({cloningDate})
  }
  _handleClosingDateChange = (closingDate)  => {
    return this.setState({closingDate})
  }

  _hideCloseTripModal = () => this.setState({showCloseTripModal: false})

  _showCloseTripModal = () => this.setState({showCloseTripModal: true})

  _onClickDeletePattern = () => {
    const {
      activeEntity,
      activePattern,
      deleteGtfsEntity,
      feedSource,
      setActiveEntity,
      showConfirmModal
    } = this.props
    const {id: patternId} = activePattern
    const {id: routeId} = activeEntity
    showConfirmModal({
      title: `Cancella il percorso?`,
      body: `Sei sicuro di voler cancellare questo percorso? Questo eliminerà tutte le corse associate al percorso.`,
      onConfirm: () => {
        // Delete trip pattern. After deletion/refetch, set active entity
        deleteGtfsEntity(feedSource.id, 'trippattern', patternId, routeId)
          // $FlowFixMe action is wrapped in dispatch
          .then(
            () => setActiveEntity(feedSource.id, 'route', activeEntity, 'trippattern')
          )
      },
      confirmButtonStyle: 'danger',
      confirmButtonText: 'Cancella percorso'
    })
  }

  _onClickNew = () => {
    const {activeEntity, feedSource, newGtfsEntity} = this.props
    const newPattern = {
      // FIXME: There is no table object in gtfs.yml for trippattern. Should there be?
      // ...generateNullProps('trippattern'),
      routeId: activeEntity.route_id,
      directionId: null,
      patternStops: [],
      name: 'Nuovo percorso',
      // FIXME should we be using some other method to generate ID?
      patternId: generateUID(),
      shapeId: generateUID(),
      useFrequency: 0,
      official_length: null,
      trip_type: null,
      shapePoints: [],
      id: ENTITY.NEW_ID
    }
    newGtfsEntity(feedSource.id, 'trippattern', newPattern, true)
  }

  /**
   * Reverse trip pattern by reversing both the order of the pattern stops and
   * the order of the shape points. NOTE: this does not change the travel times
   * for the pattern stops.
   */
  _onClickReversePattern = () => {
    const {
      activePattern,
      controlPoints,
      patternSegments,
      saveActiveGtfsEntity,
      showConfirmModal,
      updatePatternStops,
      updatePatternGeometry
    } = this.props
    showConfirmModal({
      title: `Vuoi inveritire il percorso?`,
      body: `Sei sicuro di voler invertire questo percorso?`,
      onConfirm: () => {
        // Travel time will be offset by one stop (travel time for first stop
        // should be zero).
        let defaultTravelTime = 0
        const {patternStops} = activePattern
        // Store total distance from last pattern stop
        const lastStop = patternStops[patternStops.length - 1]
        const totalDistance = lastStop.shapeDistTraveled
        // Clone pattern stops and reverse them, updating shape dist traveled
        // and travel time for the new order.
        const clonedPatternStops = [...patternStops]
          .reverse()
          .map((ps, i) => {
            const patternStop = {
              ...ps,
              // $FlowFixMe
              shapeDistTraveled: totalDistance - ps.shapeDistTraveled,
              defaultTravelTime,
              stopSequence: i
            }
            // Update default travel time for next iteration.
            defaultTravelTime = ps.defaultTravelTime
            return patternStop
          })
        updatePatternGeometry({
          // Reverse control points
          controlPoints: clone(controlPoints).reverse(),
          // Reverse order of segments and each segment's coordinate list.
          patternSegments: clone(patternSegments)
            .map(seg => seg.reverse())
            .reverse()
        })
        updatePatternStops(activePattern, clonedPatternStops)
        saveActiveGtfsEntity('trippattern')
      }
    })
  }

  render () {
    const {activeEntity, activePatternId, activePatternTripCount} = this.props
    const {tripPatterns} = activeEntity
    const patternHasTrips = activePatternTripCount > 0
    const createPatternDisabled = tripPatterns && tripPatterns.findIndex(entityIsNew) !== -1
    require('moment/locale/it') // 5t Now react-datetime will be in italian
    return (
      <div className='trip-pattern-controls'>
        <ButtonGroup
          className='pull-right'>
          {/*5t */}
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip'>Versiona percorso</Tooltip>}>
            <Button
              bsSize='small'
              disabled={!activePatternId}
              onClick={this._onClickVersionatePatternShow}
              >
              <Icon type='code-fork' />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
              placement='bottom'
              overlay={<Tooltip id='tooltip'>Termina percorso</Tooltip>}>
            <Button
              bsSize='small'
              disabled={!activePatternId}
              onClick={this._onClickClosePatternShow}
              >
              <Icon type='bomb' />
            </Button>
          </OverlayTrigger>
          {/*-5t */}
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip'>Inverti percorso</Tooltip>}>
            <Button
              bsSize='small'
              title={patternHasTrips
                ? 'Non è possibile invertire percorsi che hanno corse associate'
                : 'Seleziona percorso da invertire'}
              // Do not allow reversing trip pattern if it has trips
              disabled={!activePatternId || patternHasTrips}
              onClick={this._onClickReversePattern}>
              <Icon type='exchange' />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip'>Duplica percorso</Tooltip>}>
            <Button
              bsSize='small'
              data-test-id='duplicate-pattern-button'
              disabled={!activePatternId}
              onClick={this._onClickClonePattern}
            >
              <Icon type='clone' />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip'>Cancella percorso</Tooltip>}>
            <Button
              bsSize='small'
              data-test-id='delete-pattern-button'
              disabled={!activePatternId}
              onClick={this._onClickDeletePattern}
            >
              <Icon type='trash' />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
        <Button
          bsSize='small'
          data-test-id='new-pattern-button'
          disabled={createPatternDisabled}
          onClick={this._onClickNew}
        >
          <Icon type='plus' /> Nuovo percorso
          </Button>
          {/*5t*/}
        {this.state.showCloningDateControl &&
        <ButtonGroup>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip'>Data di termine delle corse attive del percorso</Tooltip>}>
            <div style={{width:'80%', float: 'left'}}>
              <Datetime
                timeFormat = {false}
                closeOnSelect = {true}
                dateFormat =  {getConfigProperty('application.date_format')}
                onChange={this._handleDateChange}
                value={this.state.cloningDate}
                />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip'>Conferma Versiona percorso</Tooltip>}>
            <Button

              disabled={!activePatternId}
              onClick={this._onClickVersionatePattern}
              >
              <Icon type='check' />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
        }
        {this.state.showClosingDateControl &&
        <ButtonGroup>

            <OverlayTrigger
              placement='bottom'
              overlay={<Tooltip id='tooltip'>Data di termine delle corse attive del percorso</Tooltip>}>
              <div style={{width:'80%', float: 'left'}}>
                  <Datetime
                  timeFormat = {false}
                  closeOnSelect = {true}
                  dateFormat =  {getConfigProperty('application.date_format')}
                  onChange={this._handleClosingDateChange}
                  value={this.state.closingDate}
                  />
              </div>
            </OverlayTrigger>

          <OverlayTrigger
            placement='bottom'
            overlay={<Tooltip id='tooltip'>Conferma terminazione percorso</Tooltip>}>
            <Button
              disabled={!activePatternId}
              onClick={this._onClickClosePattern}
              >
              <Icon type='check' />
              {/*-5t */}
        </Button>
          </OverlayTrigger>
        </ButtonGroup>
        }
        <TripPatternCloseTripModal
          onClose={this._hideCloseTripModal}
          show={this.state.showCloseTripModal} />
      </div>
    )
  }
}
