// @flow

import {getRouteName, mapPatternShape} from '../../editor/util/gtfs'
import {calculateNumberActiveDays} from '../../editor/selectors'

import type {Action} from '../../types/actions'
//import type {AgenciesState} from '../../types/reducers'

export const defaultState = {
  fetchStatus: {
    fetched: false,
    fetching: false,
    error: false
  },
  data: {
    calendars: []
  }
}

export default function reducer (
  state = defaultState,
  action: Action
){
  switch (action.type) {
    case 'SET_ACTIVE_FEEDVERSION':
      return defaultState
    case 'FETCH_GRAPHQL_CALENDARS':
      return {
        fetchStatus: {
          fetched: false,
          fetching: true,
          error: false
        },
        data: {
          calendars: []
        }
      }
    case 'FETCH_GRAPHQL_CALENDARS_REJECTED':
      return {
        fetchStatus: {
          fetched: false,
          fetching: false,
          error: true
        },
        data: {
          calendars: []
        }
      }
    case 'FETCH_GRAPHQL_CALENDARS_FULFILLED':
      // const calendars = action.payload
      const enhancedCalendars = action.payload.map(calendar => {
        const enhCalendar = {...calendar, numberActiveDays: calculateNumberActiveDays(calendar)}
        return enhCalendar
      })
      return {
        fetchStatus: {
          fetched: true,
          fetching: false,
          error: false
        },
        // data: action.payload
        data: enhancedCalendars
      }
    default:
      return state
  }
}
