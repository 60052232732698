// @flow

import React, { Component } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'

export type Props = {
  onComponentMount: (Props) => void,
}

export default class SummaryNumbers extends Component<Props> {



  bigNumberFormatter(cell, row){
    return cell? cell.toLocaleString('it', {maximumFractionDigits: 0}) : 0
  }



  render () {
    const {totalNumbers} = this.props

    return (

        <div style={{padding: '20px'}}>
          <Row>
            <Col xs={4} className='text-center'>
              <p
                title={this.bigNumberFormatter(totalNumbers.numLinee)}
                style={{marginBottom: '0px', fontSize: '200%'}}>
                {this.bigNumberFormatter(totalNumbers.numLinee)}
              </p>
              <p style={{marginBottom: '0px'}}>Numero linee</p>
            </Col>
            <Col xs={4} className='text-center'>
              <p
                title={this.bigNumberFormatter(totalNumbers.numPercorsi)}
                style={{marginBottom: '0px', fontSize: '200%'}}>
                {this.bigNumberFormatter(totalNumbers.numPercorsi)}
              </p>
              <p style={{marginBottom: '0px'}}>Numero percorsi</p>
            </Col>
            <Col xs={4} className='text-center'>
              <p
                title={this.bigNumberFormatter(totalNumbers.numCorse)}
                style={{marginBottom: '0px', fontSize: '200%'}}>
                {this.bigNumberFormatter(totalNumbers.numCorse)}
              </p>
              <p style={{marginBottom: '0px'}}>Numero corse</p>
            </Col>
          </Row>
          <Row style={{marginTop: 20}}>
            <Col xs={3} style={{minWidth:'250px'}}>
              <div>Totale km di linea:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.kmDiLinea)}</span></div>
              <div>- di cui urbani:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.kmDiLineaUrbani)}</span></div>
              <div>- di cui extraurbani:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.kmDiLineaExtraurbani)}</span></div>
            </Col>
            <Col xs={1}></Col>
            <Col xs={3} style={{minWidth:'250px'}}>
              <div>Totale km contribuiti:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.kmContrib)}</span></div>
              <div>- di cui urbani:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.kmContribUrbani)}</span></div>
              <div>- di cui extraurbani:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.kmContribExtraurbani)}</span></div>
            </Col>
            <Col xs={1}></Col>
            <Col xs={4} style={{minWidth:'250px'}}>
              <div>Totale km a conferma:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.kmConferma)}</span></div>
              <div>Totale posti km di linea:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.postiKmDiLinea)}</span></div>
              <div>Totale posti km a conferma:<span style={{float:'right'}}>{this.bigNumberFormatter(totalNumbers.postiKmConferma)}</span></div>
            </Col>

          </Row>

      </div>
    )
  }
}
