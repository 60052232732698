// @flow

import { connect } from 'react-redux'
import TripsReportLayout from '../components/TripsReportLayout'
import { fetchFeedInfo } from '../../../../gtfs/actions/feedInfo'
import { fetchAgencies } from '../../../../gtfs/actions/agencies'
import { fetchRoutes } from '../../../../gtfs/actions/routes'
import { fetchCalendars } from '../../../../gtfs/actions/calendars'
import {
  fetchTripsWithFilters,
  tripsPatternFilterChange,
  tripsRouteFilterChange,
  fetchAllTrips,
  setAllCsvDownloaded
} from '../../../../gtfs/actions/trips'
import {getTripsData, getTripsDataTotals, getCurrentRouteTripsCSVData, getTripsCSVData, getRouteCalendarsData} from '../../../selectors'

import type {
  dispatchFn,
  AppState,
  RouteListItem,
  ValidationPattern
} from '../../../../types/reducers'

const mapStateToProps = (state: AppState, ownProps) => {
  const {gtfs} = state
  const {
    agencies,
    calendars,
    filter,
    patterns,
    routes,
    trips
  } = gtfs
  // console.log('agencies', agencies)
  
  return {
    agencies,
    calendars, //: getRouteCalendarsData(state), //calendars.data,
    filter,
    patterns,
    routes: routes.allRoutes,
    trips,
    tripsTableData: getTripsData(state),
    tripsDataTotals: getTripsDataTotals(state),
    allCSVData: getTripsCSVData(state),
    routeCSVData: getCurrentRouteTripsCSVData(state),
    routeCalendars: getRouteCalendarsData(state), 
    feedInfo: state.gtfs.feedInfo.data.feed_info
  }
}

const mapDispatchToProps = (dispatch: dispatchFn, ownProps): any => {
  const {namespace} = ownProps.version
  return {
    onComponentMount: (initialProps) => {
      if (!initialProps.routes.fetchStatus.fetched ||
          !initialProps.agencies.fetchStatus.fetched ||
          !initialProps.calendars.fetchStatus.fetched
          ) {
        dispatch(fetchFeedInfo(namespace))
        dispatch(fetchAgencies(namespace))
        dispatch(fetchRoutes(namespace))
        dispatch(fetchCalendars(namespace))
      } else if (
        initialProps.routes.fetchStatus.fetched &&
        initialProps.patterns.fetchStatus.fetched &&
        !initialProps.trips.oneRoute.fetchStatus.fetched
      ) {
        dispatch(fetchTripsWithFilters(namespace))
      }
    },
    routeFilterChange: (route: ?RouteListItem) => {
      dispatch(tripsRouteFilterChange(namespace, route && route.route_id))
    },
    patternFilterChange: (pattern: ?ValidationPattern) => {
      dispatch(tripsPatternFilterChange(namespace, pattern && pattern.pattern_id))
    },
    getAllPatternInformations: () => {
      dispatch(fetchAllTrips(namespace))
    },
    setAllcsvStatus: () => {
      dispatch(setAllCsvDownloaded(namespace))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TripsReportLayout)
