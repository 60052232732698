// @flow

import React, {Component} from 'react'
import { Button } from 'react-bootstrap'
import Icon from '@conveyal/woonerf/components/icon'
import { getComponentMessages } from '../../common/util/config'


type Props = {
  routeTotals: any,
}


export default class RouteTotals extends Component<Props> {

  messages = getComponentMessages('TimetableHeader')

  _onClick = (name: string, route_id: string) => {
    const {activeComponent, activeEntity, feedSource, setActiveEntity} = this.props

    const forceFetch = true // 5t aggiunto per forzare fetch anche sulla stessa linea per ricalcolare i totali
    setActiveEntity(feedSource.id, activeComponent, activeEntity, null, null, null, null, forceFetch)

  }


  render () {
    const {routeTotals} = this.props

    const styles = {
      swatch: {
        padding: '5px',
        marginRight: '30px',
        background: '#fff',
        borderRadius: '4px',
        display: 'inline-block',
        cursor: 'pointer'
      },
      popover: {
        position: 'absolute',
        zIndex: '200'
      },
      cover: {
        position: 'fixed',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0'
      }
    }
    return (
      <div>
      <div style={{float:'left', display: 'grid', gridGap: '1px', backgroundColor: '#eee', gridTemplateColumns: 'auto 130px 130px 130px' }}>
        <div style={{ paddingLeft: '10px', paddingRight: '10px', backgroundColor: '#fff'}}>
          [km]<br />
          Di linea<br />
          Contribuiti<br />
          {this.props.routeTotals.totKmEffettiviConferma !== '0' && `A conferma`}
        </div>
        <div style={{ paddingLeft: '10px', backgroundColor: '#fff'}}>
          Preventivo<br />
          {this.props.routeTotals.totKmIniziali}<br />
          {this.props.routeTotals.totKmInizialiContribuiti}<br />
          {this.props.routeTotals.totKmEffettiviConferma !== '0' &&
            `${this.props.routeTotals.totKmInizialiConferma}`}
        </div>
        <div style={{ paddingLeft: '10px', backgroundColor: '#fff'}}>
          Corrente<br />
          {this.props.routeTotals.totKmEffettivi}<br />
          {this.props.routeTotals.totKmEffettiviContribuiti}<br />
          {this.props.routeTotals.totKmEffettiviConferma !== '0' &&
            `${this.props.routeTotals.totKmEffettiviConferma}`}
        </div>
        <div style={{ paddingLeft: '10px', backgroundColor: '#fff'}}>
          Proiezione<br />
          {this.props.routeTotals.totKmProiezione}<br />
          {this.props.routeTotals.totKmProiezioneContribuiti}<br />
          {this.props.routeTotals.totKmEffettiviConferma !== '0' &&
            `${this.props.routeTotals.totKmProiezioneConferma}`}
        </div>
      </div>
      <div style={{float:'left', margin:'1em', clear:'both' }}>
        <Button
          bsStyle='primary'
          style={{ marginRight:'10px' }}
          onClick={this._onClick}>
          <Icon type='refresh' />
        </Button>
        <span>Aggiorna i dati </span>
      </div>
    </div>
    )
  }
}
