// @flow

import {createAction, type ActionType} from 'redux-actions'

import {createVoidPayloadAction, fetchGraphQL} from '../../common/actions'
import {
  updateArrivalDisplay,
  updatePatternFilter,
  updateRouteFilter,
  updateTimepointFilter
} from './filter'
import {calendars} from '../../gtfs/util/graphql'
import {fetchPatterns} from './patterns'

import type {dispatchFn, getStateFn, TimetableData} from '../../types/reducers'

export const errorFetchingCalendars = createVoidPayloadAction(
  'FETCH_GRAPHQL_CALENDARS_REJECTED'
)
export const fetchingCalendars = createVoidPayloadAction(
  'FETCH_GRAPHQL_CALENDARS'
)
export const receiveCalendars = createAction(
  'FETCH_GRAPHQL_CALENDARS_FULFILLED',
  (payload: {
    data: null
  }) => payload
)

export function fetchCalendars (namespace: string) {
  return function (dispatch: dispatchFn, getState: getStateFn) {
    dispatch(fetchingCalendars())
    return dispatch(fetchGraphQL({query: calendars, variables: {namespace}}))
      .then(data => {
        if(data){
          dispatch(receiveCalendars(data.feed.calendar))
        } else {
          dispatch(errorFetchingCalendars())
        }
      })
  }
}
