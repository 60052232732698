import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import LogoAmp from './LogoAmp.js'
import LogoCasale from './LogoCasale.js'
import LogoBardo from './LogoBardo.js'
import {getConfigProperty} from '../../../../common/util/config'


const formatDatiLinea = (patternsWithTrips, tripsDataTotals) => {
	return {
		table:{
			body: [
				// [{text: 'CANOVA'},{text: routeReportDataTotals.numRoutes, alignment: 'right'}],
				[{text: `Linea ${patternsWithTrips[0].routeName}`, fontSize: 12, bold: true, }],
				[{text: `Esercita da ${patternsWithTrips[0].agencyName}`}],
				[{text: `Totale Km di linea: ${bigNumberFormatter(tripsDataTotals.totKmEffettivi)}`}],
				[{text: `Totale Km contribuiti: ${bigNumberFormatter(tripsDataTotals.totKmEffettiviContrib)}`}],
				[{text: `Totale Km a conferma: ${bigNumberFormatter(tripsDataTotals.totKmEffettiviConferma)}`}],
			]
		},
		layout: 'headerLineOnly'
	}
}


const booleanFormatter = (val) => {
	return val === 1 ? '√' : 'χ'
}

const formatTrips = (trips) => {
	const dateFormat = getConfigProperty('application.date_format') || ''
	return trips.map(trip => {
		const isBoldStart = trip.start_date.substring(4) !== '0101'
		const isBoldEnd = trip.end_date.substring(4) !== '1231'
		return ([
      {text: trip.trip_short_name},
			{text: trip.service_id},
			{text: trip.sliced_days, alignment: 'right'},
			{text: booleanFormatter(trip.contributed), alignment: 'center'},
			{text: trip.confirmation_trip === 1 ? '√' : 'χ', alignment: 'center'},
			{text: bigNumberFormatter(trip.kmEffettivi), alignment: 'right'},
			{text: trip.seat, alignment: 'right'},
			{text: trip.stand, alignment: 'right'},
			{text: moment(trip.start_date).format(dateFormat), bold:isBoldStart, alignment: 'center'},
			{text: moment(trip.end_date).format(dateFormat), bold:isBoldEnd,  alignment: 'center'},
		]);
	});
} //moment(feedInfo.feed_start_date).format(dateFormat)

const getTripsTable = (trips) => {
	const formattedTrips = formatTrips(trips)
	return {
		table: {
			headerRows: 1,
			dontBreakRows: true,
			widths: [ '15%', '*',20, 35, 15, 35, 30, 35, 52, 52],
			body: [
				[
					{text: 'Corsa', style: 'tableHeader', fillColor: '#eeeeee'},
					{text: 'Calendario', style: 'tableHeader', fillColor: '#eeeeee'},
					{text: 'gg', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
					{text: 'Contrib', style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'},
					{text: 'AC', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
					{text: 'Km', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
					{text: 'Seduti', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
					{text: 'In piedi', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
					{text: 'Inizio', style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'},
					{text: 'Fine', style: 'tableHeader', alignment: 'center', fillColor: '#eeeeee'},
				],
				...formattedTrips,
			]
		},
		layout: 'lightHorizontalLines', // optional
	}

}
const formatPatterns = (patternsWithTrips) => {
	const ret = patternsWithTrips.map( (pattern) => {
		const patternLengthFormatted = pattern.trips[0] ? (pattern.trips[0].official_length / 1000).toLocaleString('it', {maximumFractionDigits: 1}) : 0
		const tipoServizio = pattern.trips[0]
						? pattern.trips[0].trip_type === 0 ? 'urbano' : 'extraurbano'
						: '-'
		return [
			{text: [{text:'Percorso: ', bold: true}, `${pattern.id} - ${pattern.name}`], margin: [ 0, 10, 0, 5 ] },
			{text: [{text:`- Lunghezza:  ${patternLengthFormatted} Km`}, {text:`       - Tipo servizio: ${tipoServizio} `}], margin: [ 0, 0, 0, 5 ]  },
			getTripsTable(pattern.trips),
			'\n',
			'\n',
		]
	})

	return ret.flat()
}

const formatCalendars = (calendars) => {
	const sortedCalendars = calendars.sort((a,b) => (a.service_id > b.service_id) ? 1 : -1)
	
	return sortedCalendars.map(cal => {
		
		return ([
			{text: cal.service_id},
			{text: cal.description},
			{text: cal.numberActiveDays, alignment: 'right'},
		])
	})
} //


const getCalendarsTable = (calendars) => {
		const formattedCalendars = formatCalendars(calendars)
		return {
			table: {
				headerRows: 1,
				dontBreakRows: true,
				widths: [ '20%', '*', '15%'],
				body: [
					[
						{text: 'Calendario', style: 'tableHeader', fillColor: '#eeeeee'},
						{text: 'Descrizione', style: 'tableHeader', fillColor: '#eeeeee'},
						{text: 'gg di servizio', style: 'tableHeader', alignment: 'right', fillColor: '#eeeeee'},
					],
					...formattedCalendars,
				]
			},
			layout: 'lightHorizontalLines', // optional
		}
}

const formatValidoDalAl =  (feedInfo) => {
	const dateFormat = getConfigProperty('application.date_format') || ''
	return {
		table:{
			body: [
				[{text: 'Cod. Contratto'},{text: feedInfo.cod_contratto, alignment: 'right'}],
				[{text: 'Validità dal'},{text: moment(feedInfo.feed_start_date).format(dateFormat), alignment: 'right'}],
				[{text: 'Validità al'}, {text: moment(feedInfo.feed_end_date).format(dateFormat), alignment: 'right'}],
			]
		},
		layout: 'headerLineOnly'
	}
}

const bigNumberFormatter = (val) => {
	return (val === 0 || val)? val.toLocaleString('it', {maximumFractionDigits: 0}) : null
}

export default (patternsWithTrips, tripsDataTotals, feedInfo, calendars= []) => {
	const {vfs} = vfsFonts.pdfMake;
	pdfMake.vfs = vfs;

	const validoDalAlTable = formatValidoDalAl(feedInfo)
	const datiLinea = formatDatiLinea(patternsWithTrips, tripsDataTotals)
	const patterns = formatPatterns(patternsWithTrips)
	const calendarsLegend = getCalendarsTable(calendars)

	let logo = 	{
		image: 'logoAmp',
		width: 50,
		height: 50,
		absolutePosition: { x: 500, y: 30 }
	}

	if (feedInfo.feed_publisher_name.indexOf('Casale') >= 0){
		logo.image = 'logoCasale'
	}
	else if (feedInfo.feed_publisher_name.indexOf('Bardonecchia') >= 0){
		logo.image = 'logoBardonecchia'
	}

  const documentDefinition = {
		info: {
	    title: `Contratto di servizio  ${feedInfo.feed_publisher_name}`,
	    author: 'Café team',
			creator: '5T',
			producer: 'AMP',
	    subject: 'Contratto di servizio',
	    keywords: 'trasporto pubblico, servizio, contratto, 5t',
	  },
		pageSize: 'A4',
		pageOrientation: 'portrait',
		defaultStyle: {
	    fontSize: 10,
	  },
		footer: (currentPage, pageCount) => { return {text: 'pag. ' + currentPage.toString() + ' / ' + pageCount, alignment: 'right', margin: [10, 0] }},
		content: [
			logo,
			{text: 'Contratto di servizio', fontSize: 20, bold: true},
			{text: `${feedInfo.feed_publisher_name}`, fontSize: 20, bold: true},
			{text: 'Report Corse', fontSize: 14, style:{color: 'blue', italics: true, }},
			'\n',
      {
				columns:[
					{width: '50%', ...validoDalAlTable},
          {width: '50%', text: ``},
					
        ],
        columnGap: 10
      },
			'\n',
			datiLinea,
			'\n',
			...patterns,
			'\n',
			{text: 'Legenda calendari', fontSize: 14, bold: true},
			'\n',
			calendarsLegend
		],
		images: {
			...LogoAmp, ...LogoCasale, ...LogoBardo
		},
  }
	const pdf = pdfMake.createPdf(documentDefinition)
	// pdf.download()
	pdf.open()
}
