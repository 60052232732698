// @flow
import Icon from '@conveyal/woonerf/components/icon'
import React from 'react'
import {createSelector} from 'reselect'
import clone from 'lodash/cloneDeep'
import moment from 'moment'
import { orderBy } from 'natural-orderby'

import {getConfigProperty} from '../../common/util/config'
import {defaultSorter} from '../../common/util/util'
import {humanizeSeconds, secondsAfterMidnightToHHMM} from '../../common/util/gtfs'
import {STOP_NAME_SPLIT_REGEX, getEntityName} from '../../editor/util/gtfs'
import {calculateNumberActiveDays, calculateSlicedActiveDays} from '../../editor/selectors'
import { sortTrips } from '../../gtfs/util'

import type {Project} from '../../types'
import type {
  AppState,
  RouteDetail,
  TimetableStop,
  ValidationStop,
  ValidationTrip
} from '../../types/reducers'

type Column = {
  children: any,
  colSpan?: string,
  dataField?: string,
  isKey?: boolean,
  row: string,
  rowSpan?: string,
  stopId?: string,
  width: string
}

export type RouteRowData = {
  numPatterns: number,
  numStops: number,
  numTrips: number,
  routeId: string,
  routeName: string,
  tripsPerHour: Array<number>
}

export type PatternRowData = {
  numStops: number,
  numTrips: number,
  patternId: string,
  patternName: string,
  tripsPerHour: Array<number>
}

export const getActiveProjectId = (state: AppState) => state.projects.active
  ? state.projects.active.id
  : getConfigProperty('application.active_project')
export const getProjects = (state: AppState) => state.projects.all

export const getActiveProject: AppState => ?Project = createSelector(
  [getActiveProjectId, getProjects],
  (activeProjectId, projects) => {
    return projects.find(p => p.id === activeProjectId) || projects[0]
  }
)

export const getFilteredStops: AppState => Array<ValidationStop> = createSelector(
  [ state => state.gtfs.patterns, state => state.gtfs.filter.patternFilter ],
  (patterns, patternFilter) => {
    const stopsInFilteredPattern = {}
    if (patternFilter) {
      patterns.data.patterns.forEach(pattern => {
        if (pattern.pattern_id === patternFilter) {
          pattern.stops.forEach(stop => {
            stopsInFilteredPattern[stop.stop_id] = true
          })
        }
      })
    }
    return patterns.data.stops.filter(
      stop => !patternFilter || stopsInFilteredPattern[stop.stop_id]
    )
  }
)

export const getPatternData: AppState => Array<PatternRowData> = createSelector(
  [state => state.gtfs.patterns.data.patterns],
  patterns => {
    return patterns.map(pattern => {
      return {
        numStops: pattern.stops.length,
        numTrips: pattern.trips.length,
        patternId: pattern.pattern_id,
        patternName: pattern.name,
        tripsPerHour: calculateTripsPerHour(pattern.trips)
      }
    })
  }
)

// $FlowFixMe There is some flow error related to createSelector and callable signature here.
export const getRouteData: AppState => Array<RouteRowData> = createSelector(
  [state => state.gtfs.routes.routeDetails.data],
  data => {
    if (!data) return []
    return data.routes.map(route => {
      return {
        numPatterns: countPatterns(route),
        numStops: route.stops.length,
        numTrips: route.trips.length,
        routeId: route.route_id,
        // cast to make flow happy
        routeName: getEntityName((route: any)),
        tripsPerHour: calculateTripsPerHour(route.trips)
      }
    })
  }
)
/* 5t start */
const getEnhancedTripsSelector = createSelector(
  [
    state => state.gtfs.routes.routeReport.data,
    state => state.gtfs.calendars.data,
  ],
  (routesData, calendars) => {
    if (!routesData || !calendars ) return []

    const {routes} = routesData

    return routes.map(route => {
      const trips =  getEnhancedTripsMgr(route.trips, calendars)

      return {...route, trips}
    })

  })


// projects.all[0].feedSources[0].feedVersions[0].validationResult.totKmForRoute.lenght

export const getRouteReportData: AppState => Array<RouteRowData> = createSelector(
  [
    // state => state.gtfs.routes.routeReport.data,
    getEnhancedTripsSelector,
    state => state.gtfs.agencies.data,
    state => state.gtfs.calendars.data,
    state => state.gtfs.feedInfo.data.feed_info
   ],
  (routes, agencies, calendars, feedInfo) => {

    return getDatiGiusti('linea', routes, agencies, calendars, feedInfo)
  }
)

export const getCsvPreventivo: AppState => Array<RouteRowData> = createSelector(
  [
    // state => state.gtfs.routes.routeReport.data,
    getEnhancedTripsSelector,
    state => state.gtfs.agencies.data,
    state => state.gtfs.calendars.data,
    state => state.gtfs.feedInfo.data.feed_info
   ],
  (routes, agencies, calendars, feedInfo) => {

    return getDatiGiusti('preventivo', routes, agencies, calendars, feedInfo)
  }
)
export const getCsvProiezione: AppState => Array<RouteRowData> = createSelector(
  [
    //state => state.gtfs.routes.routeReport.data,
    getEnhancedTripsSelector,
    state => state.gtfs.agencies.data,
    state => state.gtfs.calendars.data,
    state => state.gtfs.feedInfo.data.feed_info
   ],
  (routes, agencies, calendars, feedInfo) => {

    return getDatiGiusti('proiezione', routes, agencies, calendars, feedInfo)
  }
)


const getDatiGiusti = (tripartito, routes, agencies, calendars, feedInfo) => {
  //tripartito vale: 'linea', 'preventivo', 'proiezione'

  if (!routes) return []
  const tipiServizio = ['U', 'E', 'U/E']
  const orderedRoutes = orderBy(routes, route => route.route_short_name)

  return orderedRoutes.map(route => {
    let routeTripType
    let numCorse = 0
    let kmDiLinea = 0
    let kmContrib = 0
    let kmDiLineaUrbani = 0
    let kmDiLineaExtraurbani = 0
    let kmContribUrbani = 0
    let kmContribExtraurbani = 0
    let kmConferma = 0
    let postiKmDiLinea = 0
    let postiKmConferma = 0

    const patternLookup = {}
    // const enhancedRouteTrips =  getEnhancedTripsMgr(route.trips, calendars)
    //inizializzo con il tipo della prima corsa...
    routeTripType = route.trips && route.trips[0] && route.trips[0].trip_type


    route.trips.forEach(trip => {
      let kmDaConsiderare = trip.kmEffettivi //nelle somme: se km linea quelli sliced, altrimenti km su base annua
      if(tripartito === 'preventivo'){
        if(trip.has_start_date) return;
        kmDaConsiderare = trip.kmSuBaseAnnua
      } else if (tripartito === 'proiezione'){
        if(trip.has_end_date) return;
        kmDaConsiderare = trip.kmSuBaseAnnua
      }
      const moltiplicatoreConferma = trip.confirmation_trip === 0 ? 1 : 0
      numCorse ++
      patternLookup[trip.pattern_id] = true
      // se continua a essere quello della prima corsa, bene, se no diventa misto (urbano/extraurbano)
      routeTripType = trip.trip_type === routeTripType ? routeTripType : 2
      kmDiLinea += kmDaConsiderare * moltiplicatoreConferma //sommo sole se conf_trip = 1
      kmContrib += trip.contributed * kmDaConsiderare * moltiplicatoreConferma   //sommo sole se conf_trip = 1
      kmDiLineaUrbani +=      kmDaConsiderare * (trip.trip_type === 0 ? 1 : 0) * moltiplicatoreConferma //sommo sole se triptype=1, urbano e no conferma
      kmDiLineaExtraurbani += kmDaConsiderare * (trip.trip_type === 1 ? 1 : 0) * moltiplicatoreConferma //sommo sole se triptype=0, cioè extra e no conferma
      kmContribUrbani += trip.contributed * kmDaConsiderare * (trip.trip_type === 0 ? 1 : 0) * moltiplicatoreConferma //contrib, sommo sole se triptype=1, urbano e no conferma
      kmContribExtraurbani += trip.contributed * kmDaConsiderare * (trip.trip_type === 1 ? 1 : 0) * moltiplicatoreConferma //contrib, sommo sole se triptype=0, cioè extra e no conferma
      kmConferma += kmDaConsiderare * (+!moltiplicatoreConferma)  // che stile il "+!": numero -> convert in bool e negato -> riconvert in num (0 o 1)
      postiKmDiLinea += (trip.seat * 1 + trip.stand * 1) * kmDaConsiderare * moltiplicatoreConferma
      postiKmConferma += (trip.seat * 1 + trip.stand * 1) * kmDaConsiderare * (+!moltiplicatoreConferma) // che stile il "+!": numero -> convert in bool e negato -> riconvert in num (0 o 1)
    })
    const agency = agencies.find( e => e.agency_id == route.agency_id)
    const tipoServizio = tipiServizio[routeTripType]

    kmDiLinea = Math.round(kmDiLinea)
    kmContrib = Math.round(kmContrib)
    kmConferma = Math.round(kmConferma)
    kmDiLineaExtraurbani = Math.round(kmDiLineaExtraurbani)
    kmDiLineaUrbani = Math.round(kmDiLineaUrbani)
    kmContribUrbani = Math.round(kmContribUrbani)
    kmContribExtraurbani = Math.round(kmContribExtraurbani)
    postiKmDiLinea = Math.round(postiKmDiLinea)
    postiKmConferma = Math.round(postiKmConferma)

    return {
      nomeFeed: feedInfo.feed_publisher_name,
      codiceContratto: feedInfo.cod_contratto,
      // idEnte: feedInfo.id_ente,
      idAzienda: agency && agency.agency_id,
      azienda: agency && agency.agency_name,
      codiceLinea: route.route_short_name,
      linea: route.route_long_name,
      numPercorsi: Object.keys(patternLookup).length,
      numCorse,
      tipoServizio,
      kmDiLinea,
      kmContrib,
      kmConferma,
      kmDiLineaUrbani,
      kmDiLineaExtraurbani,
      kmContribUrbani,
      kmContribExtraurbani,
      postiKmDiLinea,
      postiKmConferma,
    }
  })

}
class Numeroni {
  numLinee = 0
  numPercorsi = 0
  numCorse = 0
  kmDiLinea = 0
  kmContrib = 0
  kmDiLineaUrbani = 0
  kmDiLineaExtraurbani = 0
  kmContribUrbani = 0
  kmContribExtraurbani = 0
  kmConferma = 0
  postiKmDiLinea = 0
  postiKmConferma = 0
  patternLookup = {}

  roundAll = () => {
    this.kmDiLinea = Math.round(this.kmDiLinea)
    this.kmContrib = Math.round(this.kmContrib)
    this.kmConferma = Math.round(this.kmConferma)
    this.kmDiLineaExtraurbani = Math.round(this.kmDiLineaExtraurbani)
    this.kmDiLineaUrbani = Math.round(this.kmDiLineaUrbani)
    this.kmContribUrbani = Math.round(this.kmContribUrbani)
    this.kmContribExtraurbani = Math.round(this.kmContribExtraurbani)
    this.postiKmDiLinea = Math.round(this.postiKmDiLinea)
    this.postiKmConferma = Math.round(this.postiKmConferma)
  }
}

//const getDatiGiusti2 = (data,  calendars) => {
export const getRouteReportDataTotals = createSelector(
  [ // state => state.gtfs.routes.routeReport.data,
    getEnhancedTripsSelector,
    state => state.gtfs.calendars.data        //arrivano già WithActiveDays
  ],
  (routes, calendars) => {
    if(!routes) return {}
    // console.log('inizio', (new Date()).getSeconds(), (new Date()).getMilliseconds())

    const tipiServizio = ['U', 'E', 'U/E']

    const oggettone = {
      totaliCorrente: new Numeroni(),
      totaliPreventivo: new Numeroni(),
      totaliProiezione: new Numeroni(),
    }
    const tripartiti = Object.keys(oggettone)

    routes.forEach(route => {

      // const enhancedRouteTrips =  getEnhancedTripsMgr(route.trips, calendars)


      route.trips.forEach(trip => {
        tripartiti.forEach(tripartito =>{
          let kmDaConsiderare = trip.kmEffettivi //nelle somme: se km linea quelli sliced, altrimenti km su base annua
          if(tripartito === 'totaliPreventivo'){
            if(trip.has_start_date) return;
            kmDaConsiderare = trip.kmSuBaseAnnua
          } else if (tripartito === 'totaliProiezione'){
            if(trip.has_end_date) return;
            kmDaConsiderare = trip.kmSuBaseAnnua
          }
          const moltiplicatoreConferma = trip.confirmation_trip === 0 ? 1 : 0
          oggettone[tripartito].numCorse ++
          oggettone[tripartito].patternLookup[trip.pattern_id] = true
          oggettone[tripartito].kmDiLinea += kmDaConsiderare * moltiplicatoreConferma //sommo sole se conf_trip = 1
          oggettone[tripartito].kmContrib += trip.contributed * kmDaConsiderare * moltiplicatoreConferma   //sommo sole se conf_trip = 1
          oggettone[tripartito].kmDiLineaUrbani +=      kmDaConsiderare * (trip.trip_type === 0 ? 1 : 0) * moltiplicatoreConferma //sommo sole se triptype=1, urbano e no conferma
          oggettone[tripartito].kmDiLineaExtraurbani += kmDaConsiderare * (trip.trip_type === 1 ? 1 : 0) * moltiplicatoreConferma //sommo sole se triptype=0, cioè extra e no conferma
          oggettone[tripartito].kmContribUrbani += trip.contributed * kmDaConsiderare * (trip.trip_type === 0 ? 1 : 0) * moltiplicatoreConferma //contrib, sommo sole se triptype=1, urbano e no conferma
          oggettone[tripartito].kmContribExtraurbani += trip.contributed * kmDaConsiderare * (trip.trip_type === 1 ? 1 : 0) * moltiplicatoreConferma //contrib, sommo sole se triptype=0, cioè extra e no conferma
          oggettone[tripartito].kmConferma += kmDaConsiderare * (+!moltiplicatoreConferma)  // che stile il "+!": numero -> convert in bool e negato -> riconvert in num (0 o 1)
          oggettone[tripartito].postiKmDiLinea += (trip.seat * 1 + trip.stand * 1) * kmDaConsiderare * moltiplicatoreConferma
          oggettone[tripartito].postiKmConferma += (trip.seat * 1 + trip.stand * 1) * kmDaConsiderare * (+!moltiplicatoreConferma) // che stile il "+!": numero -> convert in bool e negato -> riconvert in num (0 o 1
        })
      })


    })
    tripartiti.forEach(tripartito =>{
      oggettone[tripartito].numPercorsi = Object.keys(oggettone[tripartito].patternLookup).length
      delete oggettone[tripartito].patternLookup
      oggettone[tripartito].roundAll()
      oggettone[tripartito].numLinee = routes.length
    })
    // console.log('fine', (new Date()).getSeconds(), (new Date()).getMilliseconds())
    return oggettone

})


export const getCsvLinea: AppState => Array<RouteRowData> = createSelector(
  [ state => state.gtfs.routes.routeReport.data,
    state => state.gtfs.agencies.data,
    state => state.gtfs.calendars.data,
   ],
  (data, agencies, calendars) => {
    if (!data || !agencies || !calendars || !calendars.length) return []
  })

/*
export const getRouteReportDataTotals = createSelector(
  [getRouteReportData, getCsvPreventivo, getCsvProiezione],
    (routesDiLinea, routesPreventivo, routesProiezione) => {
      const arrayRoutes = [routesDiLinea, routesPreventivo, routesProiezione]
      const arrayLabel = ['totaliCorrente', 'totaliPreventivo', 'totaliProiezione']
      let returnObject = {}
      arrayRoutes.forEach((routes, i) => {
        returnObject[arrayLabel[i]] =  routes.reduce(
          (totaloni,  {codiceLinea, numPercorsi, numCorse, kmDiLinea, kmContrib, kmConferma, kmDiLineaUrbani,
            kmDiLineaExtraurbani, kmContribUrbani, kmContribExtraurbani, postiKmDiLinea, postiKmConferma}) => {
            return {
              numRoutes: totaloni.numRoutes + 1,
              numPatterns: totaloni.numPatterns + numPercorsi,
              numTrips:  totaloni.numTrips + numCorse,
              kmDiLinea:  totaloni.kmDiLinea + kmDiLinea,
              kmContrib:  totaloni.kmContrib + kmContrib,
              kmConferma: totaloni.kmConferma + kmConferma,
              kmDiLineaUrbani: totaloni.kmDiLineaUrbani + kmDiLineaUrbani,
              kmDiLineaExtraurbani: totaloni.kmDiLineaExtraurbani + kmDiLineaExtraurbani,
              kmContribUrbani: totaloni.kmContribUrbani + kmContribUrbani,
              kmContribExtraurbani: totaloni.kmContribExtraurbani + kmContribExtraurbani,
              postiKmDiLinea: totaloni.postiKmDiLinea + postiKmDiLinea,
              postiKmConferma: totaloni.postiKmConferma + postiKmConferma,
            }
          }, {
            numRoutes: 0,
            numPatterns: 0,
            numTrips: 0,
            kmDiLinea: 0,
            kmContrib: 0,
            kmConferma: 0,
            kmDiLineaUrbani: 0,
            kmDiLineaExtraurbani: 0,
            kmContribUrbani: 0,
            kmContribExtraurbani: 0,
            postiKmDiLinea: 0,
            postiKmConferma: 0,
          }
        )

      })

      return returnObject
    }
  )
*/
const activeRouteId = (state) => state.gtfs.trips.oneRoute.data.feed.patterns[0].route_id

const getEnhancedTripsMgr = (trips, calendars) => {

  if(!trips || !calendars || !calendars.length) return []
  
  const enhancedTrips = trips.map( trip => {
    const calendar = calendars.find( e => e.service_id === trip.service_id)
    if(!calendar){
      console.log('che corsa è che non trovo il suo calendar??', trip)
    }
    // const numberActiveDays = calculateNumberActiveDays(calendar)
    //5t if the trip does not define start_date nor end_date, then sliced_days= calendar.numberActiveDays
    // const tripHasDefaultDates = !trip.start_date && !trip.end_date
    // const sliced_days = tripHasDefaultDates ? calendar.numberActiveDays : calculateSlicedActiveDays(trip, calendar)
    const sliced_days =  calculateSlicedActiveDays(trip, calendar)
    const enhancedTrip = {
      ...trip,
      trip_short_name: trip.trip_short_name || trip.trip_id, // così sui report appare qs anche se non c'è lo shortname
      numberActiveDays: calendar.numberActiveDays,
      sliced_days,
      seat: trip.seat,
      stand: trip.stand,
      // km_tot: trip.official_length * calendar.numberActiveDays / 1000,
      has_start_date: trip.start_date ? 1 : 0,
      has_end_date: trip.end_date ? 1 : 0,
      start_date: trip.start_date || calendar.start_date, // NB: se la trip ha le proprie start/end date sovrascrive quelle e prende le sue!
      end_date: trip.end_date || calendar.end_date,
      kmSuBaseAnnua: calendar.numberActiveDays * trip.official_length / 1000,
      kmEffettivi: sliced_days * trip.official_length / 1000,
    }
    return enhancedTrip
  })
  return enhancedTrips
}



export const getTripsData: AppState => Array<TripRowData> = createSelector(
  [
    state => state.gtfs.trips.oneRoute.data,
    state => state.gtfs.routes.allRoutes.data,
    state => state.gtfs.agencies.data,
    state => state.gtfs.calendars.data,
  ],
  (reduxTrips, routes, agencies, calendars) => {
    if (!reduxTrips || !routes || !agencies || !calendars) return []


    return reduxTrips.feed.patterns.sort(defaultSorter).map(pattern => {
      const route = routes.find( e => e.route_id == pattern.route_id)
      const agency = agencies.find(e => e.agency_id == route.agency_id)
      //const calendar = calendas.find( e => e.service_id == pattern.route_id)
      // ciclo sulle corse del pattern per enhancarle di attributi del calendars



      const enhancedPattern = {
        ...pattern,
        routeName: route.route_name,
        numRoute: route.route_id,
        agencyName: agency && agency.agency_name,
        trips: getEnhancedTripsMgr(pattern.trips, calendars)
      }
      return  enhancedPattern
    })
  }
)

export const getTripsDataTotals = createSelector(
  getTripsData,
  (patterns) => {
    if(!patterns) return null
    const trips = flatTrips(patterns)
    return trips.reduce(
      (totaloni,  {contributed, kmEffettivi, confirmation_trip}) => {
        return {
          totKmEffettivi:  totaloni.totKmEffettivi + kmEffettivi * (confirmation_trip === 0 ? 1 : 0), //sommo sole se conf_trip = 0
          totKmEffettiviContrib:  contributed && confirmation_trip === 0 ? totaloni.totKmEffettiviContrib + kmEffettivi : totaloni.totKmEffettiviContrib,
          totKmEffettiviConferma: confirmation_trip === 1 ? totaloni.totKmEffettiviConferma + kmEffettivi : totaloni.totKmEffettiviConferma,
        }
      }, {
        totKmEffettivi: 0,
        totKmEffettiviContrib: 0,
        totKmEffettiviConferma: 0,
      }
    )

  }
)



/* Restituisce i soli calendari usati da una linea (legenda calendari nel report corse pdf) */
export const getRouteCalendarsData: AppState => Array<TripRowData> = createSelector(
  [
    state => state.gtfs.trips.oneRoute.data,
    state => state.gtfs.calendars.data,
  ],
  (patternsData, calendars) => {
    if (!patternsData || !calendars) return []
    const routeSerciceIds = new Set()
    for (const pattern of patternsData.feed.patterns){
      const {trips} = pattern
      for (const trip of trips) {
        routeSerciceIds.add(trip.service_id)
      }
    }
    
    return calendars.filter( cal => routeSerciceIds.has(cal.service_id))

  }
)



const flatTrips = (patterns) => {
  return patterns.map(pattern => pattern.trips).flat()
}

export const getCurrentRouteTripsCSVData: AppState => Array<TripRowData> = createSelector(
  [
    state => state.gtfs.trips.oneRoute.data,
    state => state.gtfs.routes.allRoutes.data,
    state => state.gtfs.agencies.data,
    state => state.gtfs.calendars.data,
    state => state.gtfs.feedInfo.data.feed_info
  ],
  (reduxTrips, routes, agencies, calendars, feedInfo) => {
    if (!reduxTrips || !routes || !agencies || !calendars || !calendars.length) return []

    const p = reduxTrips.feed.patterns.map(pattern => {
      const route = routes.find( e => e.route_id == pattern.route_id)
      const agency = agencies.find(e => e.agency_id == route.agency_id)
      // const trips = pattern.trips
      const trips = getEnhancedTripsMgr(pattern.trips, calendars)
      return trips.map( trip => {
        const calendar = calendars.find( e => e.service_id == trip.service_id)
        // NB: se la trip ha le proprie start/end date sovrascrive quelle e prende le sue!
        // const numberActiveDays = calculateNumberActiveDays(calendar)
        const inizio = moment(trip.start_date || calendar.start_date).format('YYYY-MM-DD')
        const fine = moment(trip.end_date || calendar.end_date).format('YYYY-MM-DD')
        const inizioFrequenza = moment(calendar.start_date).format('YYYY-MM-DD')
        const fineFrequenza = moment(calendar.end_date).format('YYYY-MM-DD')
        const csvData = {
          nomeFeed: feedInfo.feed_publisher_name,
          idAzienda: agency && agency.agency_id,
          azienda: agency && agency.agency_name,
          numLinea: route.route_id,
          linea: route.route_long_name,
          idPercorso: pattern.id,
          percorso: pattern.name,
          lunghezzaContrattualeKm: trip.official_length / 1000,
          tripId: trip.trip_id,
          codiceCorsa: trip.trip_short_name,
          contribuita: trip.contributed,
          calendario: trip.service_id,
          inizioValiditaCorsa: inizio,
          fineValiditaCorsa: fine,
          // numGiorni: numberActiveDays,
          numGiorniCorsa: trip.sliced_days,
          // kmTot: trip.official_length * numberActiveDays / 1000,
          kmDiLinea: trip.official_length * trip.sliced_days / 1000,
          kmPreventivo: trip.has_start_date ? 0 : trip.official_length * calendar.numberActiveDays / 1000,
          kmProiezione: trip.has_end_date ? 0 : trip.official_length * calendar.numberActiveDays / 1000,
          postiSeduti: trip.seat,
          postiInPiedi: trip.stand,
          tipoCorsa: trip.trip_type < 2 ? (trip.trip_type === 0 ? 'urbano' : 'extraurbano') : trip.trip_type,
          aConferma: trip.confirmation_trip,
          lunedi: calendar.monday,
          martedi: calendar.tuesday,
          mercoledi: calendar.wednesday,
          giovedi: calendar.thursday,
          venerdi: calendar.friday,
          sabato: calendar.saturday,
          domenica: calendar.sunday,
          inizioFrequenza: inizioFrequenza,
          fineFrequenza: fineFrequenza,
          numGiorniFrequenza: calendar.numberActiveDays,

        }
        return csvData
      })
    })
    return [].concat(...p)
  }
)

export const getTripsCSVData: AppState => Array<TripRowData> = createSelector(
  [
    state => state.gtfs.trips.allRoutes.data,
    state => state.gtfs.routes.allRoutes.data,
    state => state.gtfs.agencies.data,
    state => state.gtfs.calendars.data,
    state => state.gtfs.feedInfo.data.feed_info
  ],
  (reduxTrips, routes, agencies, calendars, feedInfo) => {
    if (!reduxTrips || !routes || !agencies || !calendars) return []

    const p = reduxTrips.feed.patterns.map(pattern => {
      const route = routes.find( e => e.route_id == pattern.route_id)
      const agency = agencies.find(e => e.agency_id == route.agency_id)
      // const trips = pattern.trips
      const trips = getEnhancedTripsMgr(pattern.trips, calendars)
      return trips.map( trip => {
        const calendar = calendars.find( e => e.service_id == trip.service_id)
        // NB: se la trip ha le proprie start/end date sovrascrive quelle e prende le sue!
        // const numberActiveDays = calculateNumberActiveDays(calendar)
        const inizio = moment(trip.start_date || calendar.start_date).format('YYYY-MM-DD')
        const fine = moment(trip.end_date || calendar.end_date).format('YYYY-MM-DD')
        const inizioFrequenza = moment(calendar.start_date).format('YYYY-MM-DD')
        const fineFrequenza = moment(calendar.end_date).format('YYYY-MM-DD')
        const csvData = {
          nomeFeed: feedInfo.feed_publisher_name,
          idAzienda: agency && agency.agency_id,
          azienda: agency && agency.agency_name,
          numLinea: route.route_id,
          linea: route.route_long_name,
          idPercorso: pattern.id,
          percorso: pattern.name,
          lunghezzaContrattualeKm: trip.official_length / 1000,
          tripId: trip.trip_id,
          codiceCorsa: trip.trip_short_name,
          contribuita: trip.contributed,
          calendario: trip.service_id,
          inizioValiditaCorsa: inizio,
          fineValiditaCorsa: fine,
          // numGiorni: numberActiveDays,
          numGiorniCorsa: trip.sliced_days,
          // kmTot: trip.official_length * numberActiveDays / 1000,
          kmDiLinea: trip.official_length * trip.sliced_days / 1000,
          kmPreventivo: trip.has_start_date ? 0 : trip.official_length * calendar.numberActiveDays / 1000,
          kmProiezione: trip.has_end_date ? 0 : trip.official_length * calendar.numberActiveDays / 1000,
          postiSeduti: trip.seat,
          postiInPiedi: trip.stand,
          tipoCorsa: trip.trip_type < 2 ? (trip.trip_type === 0 ? 'urbano' : 'extraurbano') : trip.trip_type,
          aConferma: trip.confirmation_trip,
          lunedi: calendar.monday,
          martedi: calendar.tuesday,
          mercoledi: calendar.wednesday,
          giovedi: calendar.thursday,
          venerdi: calendar.friday,
          sabato: calendar.saturday,
          domenica: calendar.sunday,
          inizioFrequenza: inizioFrequenza,
          fineFrequenza: fineFrequenza,
          numGiorniFrequenza: calendar.numberActiveDays,

        }
        return csvData
      })
    })
    return [].concat(...p)
  }
)

export const getTripSchedulesCSVData: AppState => Array<TripScheduleRowData> = createSelector(
  // [getPatterns, state => state.gtfs.routes.allroutes],
  [
    state => state.gtfs.tripschedules.allRoutes.data, 
    state => state.gtfs.agencies.data, 
    state => state.gtfs.calendars.data,
    state => state.gtfs.feedInfo.data.feed_info
  ],
  (tripSchedules, agencies, calendars, feedInfo) => {
    if (!tripSchedules || !agencies || !calendars || !feedInfo) return []

    const r = tripSchedules.feed.routes.map(route => {
      const patterns = route.patterns
      const agency = agencies.find(e => e.agency_id == route.agency_id)
      const p = patterns.map(pattern =>{
        const stops = pattern.stops
        const trips = pattern.trips
        const t = trips.map(trip => {
          const calendar = calendars.find( e => e.service_id == trip.service_id)
          // NB: se la trip ha le proprie start/end date sovrascrive quelle e prende le sue!
          // const numberActiveDays = calculateNumberActiveDays(calendar)
          const inizio = moment(trip.start_date || calendar.start_date).format('YYYY-MM-DD')
          const fine = moment(trip.end_date || calendar.end_date).format('YYYY-MM-DD')
          const stoptimes =trip.stop_times
          const tripStart = stoptimes[0].departure_time
          const tripArrival = stoptimes[stoptimes.length - 1].arrival_time
          const commercialSpeed = (trip.official_length * 3.6 /(tripArrival - tripStart)).toFixed(2)
          return stoptimes.map(stoptime => {
            const stop = stops.find( e => e.stop_id == stoptime.stop_id)
            const csvData = {
              nomeFeed: feedInfo.feed_publisher_name,
              idAzienda: agency && agency.agency_id,
              azienda: agency && agency.agency_name,
              numLinea: route.route_id,
              linea: route.route_long_name,
              idPercorso: pattern.id,
              percorso: pattern.name,
              kmPercorso: trip.official_length / 1000,
              tripId: trip.trip_id,
              codiceCorsa: trip.trip_short_name,
              tipoCorsa: trip.trip_type < 2 ? (trip.trip_type === 0 ? 'urbano' : 'extraurbano') : trip.trip_type,
              aConferma: trip.confirmation_trip,
              inizioValiditaCorsa: inizio,
              fineValiditaCorsa: fine,
              progressivo: stoptime.stop_sequence + 1,
              codFermata: stop.stop_code,
              fermata: stop.stop_name,
              arrivo: stoptime.arrival_time ? moment().startOf('day').seconds(stoptime.arrival_time).format('HH:mm') : '--:--',
              partenza: stoptime.departure_time ? moment().startOf('day').seconds(stoptime.departure_time).format('HH:mm') : '--:--',
              velocitaCommercialeCorsa: commercialSpeed,

            }
            return  csvData
          })
        })
        return [].concat(...t)
      })
      return [].concat(...p)
    })
    return [].concat(...r)
  }
)

export const getPatternsWithSchedulesData: AppState => Array<TripScheduleRowData> = createSelector(
  // [getPatterns, state => state.gtfs.routes.allroutes],
  [state => state.gtfs.tripschedules.oneRoute.data, state => state.gtfs.agencies.data],
  (data, agencies) => {
    if (!data || !agencies) return []

      const route = data.feed.routes[0]
      const foundAgency = agencies.find(e => e.agency_id == route.agency_id)
      const enhancedPatterns = route.patterns.sort(defaultSorter).map(pattern =>{
        pattern.agencyName = foundAgency && foundAgency.agency_name
        pattern.routeShortName = route.route_short_name
        pattern.routeName = route.route_long_name
        // riassegno le trips

        const clonedTrips = clone(sortTrips(pattern.trips /*, pattern.use_frequency che è undefined*/))
        pattern.trips = clonedTrips.map(trip => {
          trip.stop_times = trip.stop_times.map(stoptime => {
            const foundStop = pattern.stops.find( e => e.stop_id == stoptime.stop_id)
            return {
              ...stoptime,
              stopSequence: stoptime.stop_sequence + 1,
              stopName: foundStop.stop_name,
              stopCode: foundStop.stop_code,
              arrivalTime : stoptime.arrival_time ? moment().startOf('day').seconds(stoptime.arrival_time).format('HH:mm') : '--:--',
              departureTime : stoptime.departure_time ? moment().startOf('day').seconds(stoptime.departure_time).format('HH:mm') : '--:--'
            }
          })
          return trip
        })
        pattern.pea = []
          // [1, '132568', 'stazione ferroviaria', '8.00', '9.00','10.00'],
          // [2, '454568', 'bar dello stadio', '8.10', '9.10','10.10'],
          const i = 0
          if(pattern.trips[0])
            pattern.trips[0].stop_times.forEach((stopTime, i) => {
              const rigaIesima = {
                stopSequence: pattern.trips[0].stop_times[i].stopSequence,
                stopCode: pattern.trips[0].stop_times[i].stopCode,
                stopName: pattern.trips[0].stop_times[i].stopName
              }
              pattern.trips.forEach((trip, j) => {
                rigaIesima[`t${j}_arrivalTime`] = trip.stop_times[i].arrivalTime
                // rigaIesima[`t${j}_departureTime`] = trip.stop_times[i].departureTime
              })
              pattern.pea.push(rigaIesima)
            })

        return pattern
      })
      return enhancedPatterns
  }
)

export const getCurrentRouteTripSchedulesCSVData: AppState => Array<TripScheduleRowData> = createSelector(
  // [getPatterns, state => state.gtfs.routes.allroutes],
  [
    state => state.gtfs.tripschedules.oneRoute.data, 
    state => state.gtfs.agencies.data,  
    state => state.gtfs.calendars.data,
    state => state.gtfs.feedInfo.data.feed_info
  ],
  (tripSchedules, agencies, calendars, feedInfo) => {
    if (!tripSchedules || !agencies || !calendars || !feedInfo) return []

    const r = tripSchedules.feed.routes.map(route => {
      const patterns = route.patterns
      const agency = agencies.find(e => e.agency_id == route.agency_id)
      const p = patterns.map(pattern =>{
        const stops = pattern.stops
        const trips = pattern.trips
        const t = trips.map(trip => {
          const calendar = calendars.find( e => e.service_id == trip.service_id)
          // NB: se la trip ha le proprie start/end date sovrascrive quelle e prende le sue!
          // const numberActiveDays = calculateNumberActiveDays(calendar)
          const inizio = moment(trip.start_date || calendar.start_date).format('YYYY-MM-DD')
          const fine = moment(trip.end_date || calendar.end_date).format('YYYY-MM-DD')
          const stoptimes =trip.stop_times
          const tripStart = stoptimes[0].departure_time
          const tripArrival = stoptimes[stoptimes.length - 1].arrival_time
          const commercialSpeed = (trip.official_length * 3.6 /(tripArrival - tripStart)).toFixed(2)
          return stoptimes.map(stoptime => {
            const stop = stops.find( e => e.stop_id == stoptime.stop_id)
            const csvData = {
              nomeFeed: feedInfo.feed_publisher_name,
              idAzienda: agency && agency.agency_id,
              azienda: agency && agency.agency_name,
              numeroLinea: route.route_id,
              linea: route.route_long_name,
              idPercorso: pattern.id,
              percorso: pattern.name,
              lunghezzaContrattualeKm: trip.official_length / 1000,
              tripId: trip.trip_id,
              codiceCorsa: trip.trip_short_name,
              tipoCorsa: trip.trip_type < 2 ? (trip.trip_type === 0 ? 'urbano' : 'extraurbano') : trip.trip_type,
              aConferma: trip.confirmation_trip,
              inizioValiditaCorsa: inizio,
              fineValiditaCorsa: fine,
              progressivo: stoptime.stop_sequence,
              codFermata: stop.stop_code,
              fermata: stop.stop_name,
              arrivo: stoptime.arrival_time ? moment().startOf('day').seconds(stoptime.arrival_time).format('HH:mm') : '--:--',
              partenza: stoptime.departure_time ? moment().startOf('day').seconds(stoptime.departure_time).format('HH:mm') : '--:--',
              velocitaCommercialeCorsa: commercialSpeed,
            }
            return  csvData
          })
        })
        return [].concat(...t)
      })
      return [].concat(...p)
    })
    return [].concat(...r)
  }
)
/* 5t start */
/**
 * Count patterns for a route (based on trips contained therein).
 */
function countPatterns (route: RouteDetail): number {
  const patternLookup = {}
  route.trips.forEach(trip => { patternLookup[trip.pattern_id] = true })
  return Object.keys(patternLookup).length
}

/**
 * Calculate trips per hour for an array of trips.
 */
function calculateTripsPerHour (trips: Array<ValidationTrip>): Array<number> {
  // Create array for 28 hours, which accounts for midnight to 4am the next day
  // in order to show trips per hour for trips that start after the service day
  // ends.
  const tripsPerHour = Array(28).fill(0)
  trips.forEach(trip => {
    const startTimes = []
    if (trip.frequencies.length > 0) {
      // Compile start times if the trip is frequency based.
      trip.frequencies.forEach(frequency => {
        let startTime = frequency.start_time
        // Add start times for all trips in frequency period.
        while (startTime < frequency.end_time) {
          startTimes.push(startTime)
          startTime += frequency.headway_secs
        }
      })
    } else {
      // Otherwise, just use the single start time based on first stop time.
      startTimes.push(trip.stop_times[0].arrival_time)
    }
    startTimes.forEach(startTime => {
      tripsPerHour[Math.floor(startTime / 3600)]++
    })
  })
  return tripsPerHour
}

const CELL_FORMAT = {
  FASTER: {
    // green: for travel times that are less than average (i.e., quicker)
    color: '102',
    text: 'più rapido del solito',
    icon: <Icon type='caret-down' />
  },
  SLOWER: {
    // red: for travel times that are longer than average (i.e., slower)
    color: '0',
    text: 'più lento del solito',
    icon: <Icon type='caret-up' />
  },
  EQUAL: {
    // blue: blue should never actually appear because the light should be zero
    // in this case (black)
    color: '200',
    text: 'usuale',
    icon: null
  }
}

/**
 * Create a list of columns and rows to render a bootstrap table
 * This function is dependent on the data in the gtfs.timetables reducers and
 * also gtfs.filter
 * if the showArrivals filter is set to true, we need to create an extra header row that
 * will display the arrival and departure columns beneath each stop
 * if the titimepointFilter is set to true, we only show stops that have at
 * least one stop time that has a non-blank value
 */
export const getTimetableData: AppState => {
  columns: Array<Column>,
  rows: Array<any>
} = createSelector(
  [
    (state: AppState) => state.gtfs.timetables,
    (state: AppState) => state.gtfs.filter.showArrivals,
    (state: AppState) => state.gtfs.filter.timepointFilter
  ],
  (timetables, showArrivals, timepointFilter) => {
    if (!timetables.data || !timetables.data.feed) return {columns: [], rows: []}

    // assume 1st pattern is the only one we're interested in
    const pattern = timetables.data.feed.patterns[0]

    // there could be no patterns found or no trips associate with that pattern
    // if eiter happens, return blank
    if (!pattern || pattern.trips.length === 0) return {columns: [], rows: []}

    // create stop lookup because the stops come back out-of-order
    const stopLookup: { [string]: TimetableStop } = {}
    pattern.stops.forEach(stop => {
      stopLookup[stop.stop_id] = stop
    })
    // create lookup for stop columns to check if at least one trip has times for
    // the stop.
    const columnHasStopTimeWithTime: { [string]: boolean } = {}

    // make rest of the row headers by making stop names as headers
    // assume that the first trip is representative of stops in timetable
    const firstTrip = pattern.trips[0]
    const STOP_COL_WIDTH = showArrivals ? 170 : 85
    // Store inter-stop travel times and headways in formatExtraData to use
    // with conditional formatting.
    const formatExtraData = []
    let previousStartTime
    // Add row data
    const rows = pattern.trips.map((trip, tripIndex) => {
      const row = {
        tripId: trip.trip_id,
        tripHeadsign: trip.trip_headsign,
        tripShortName: trip.trip_short_name
      }
      let previousIndexWithTimes = 0
      for (let index = 0; index < trip.stop_times.length; index++) {
        const stopTime = trip.stop_times[index]
        // Add empty array for tracking headway and travel time diffs.
        if (tripIndex === 0) formatExtraData.push([])
        const {arrival_time: arrivalTime, departure_time: departureTime} = stopTime
        if (
          isNumber(arrivalTime) ||
          isNumber(departureTime)
        ) {
          // Keep track of whether column has times (for any trip).
          columnHasStopTimeWithTime[`${index}`] = true
          if (index > 0) {
            // Record occurences of travel times for conditional formatting
            // TODO: Determine if this causes issues when departure_time is missing.
            const diff = +departureTime - row[`${previousIndexWithTimes}-departure`]
            const entry = formatExtraData[index].find(e => e.diff === diff)
            if (!entry) formatExtraData[index].push({diff, count: 1})
            else entry.count++
            previousIndexWithTimes = index
          }
        } else {
          // If missing times, add a blank entry for travel time.
          const entry = formatExtraData[index].find(e => e.diff === null)
          if (!entry) formatExtraData[index].push({diff: null, count: 1})
          else entry.count++
        }
        row[`${index}-arrival`] = arrivalTime
        row[`${index}-departure`] = departureTime
        // Store the dwell time to render in uncompressed view
        const dwellTime = +departureTime - +arrivalTime
        row[`${index}-dwell`] = dwellTime === 0
          ? '--'
          : `${Math.floor((dwellTime) / 60 * 100) / 100} min.`
      }

      return row
    })

    // Sort rows based off of the first non-blank stop time that each row shares
    rows
      .sort((a, b) => {
        for (let i = 0; i < firstTrip.stop_times.length; i++) {
          const arrivalKey = `${i}-arrival`
          if (isNumber(a[arrivalKey]) && isNumber(b[arrivalKey])) {
            return a[arrivalKey] - b[arrivalKey]
          }
          const departKey = `${i}-departure`
          if (isNumber(a[departKey]) && isNumber(b[departKey])) {
            return a[departKey] - b[departKey]
          }
        }
        return 0
      })
      // Record headways for conditional formatting
      .forEach(row => {
        // For first departure time, record times between trips.
        const diff = row[`${0}-departure`] - previousStartTime
        // console.log(stopTime, departureTime, previousStartTime)
        const entry = formatExtraData[0].find(e => e.diff === diff)
        if (!entry) formatExtraData[0].push({diff, count: 1})
        else entry.count++
        previousStartTime = row[`${0}-departure`]
      })
    // Add hidden column headers with trip info (TODO: show these in tooltip?).
    const columns: Array<Column> = [
      {
        dataField: 'tripId',
        isKey: true,
        children: 'Trip ID',
        rowSpan: showArrivals ? '2' : '1',
        row: '0',
        hidden: true,
        width: '150'
      },
      {
        dataField: 'tripHeadsign',
        children: 'Trip Headsign',
        hidden: true,
        rowSpan: showArrivals ? '2' : '1',
        row: '0',
        width: '150'
      },
      {
        dataField: 'tripShortName',
        children: 'Trip Short Name',
        hidden: true,
        rowSpan: showArrivals ? '2' : '1',
        row: '0',
        width: '150'
      }
    ]
    // Add columns for stop times. Each cell will get a conditional formatting
    // function that depends on the column index, so it must be constructed in
    // this iterator.
    firstTrip.stop_times.forEach((stopTime, i) => {
      const { stop_id: stopId } = stopTime
      const stop = stopLookup[stopId]
      if (!stop) console.warn(`Stop with stop_id=${stopId} not found in lookup`)
      const stopName = stop ? stop.stop_name : stopId
      const stopNameParts = stopName.split(STOP_NAME_SPLIT_REGEX)
      // NOTE: stopSpan surrounded by JSX to ensure that react-bootstrap-table
      // uses headerText prop in the title attribute (i.e., so that it shows the
      // full stop name intact).
      const stopSpan = stopNameParts.length === 3
        ? <span>{stopNameParts[0]}<br />{stopNameParts[2]}</span>
        : <span>{stopName}</span>
      // Data format provides conditional formatting to table cell text color.
      // Slower travel times render in red text, quicker in green.
      const dataFormat = (cell, row, extraData, rowIndex) => {
        // Find last column with valid departure time to get travel time.
        let lastStopTimeWithTimes = i - 1
        for (let j = lastStopTimeWithTimes; j >= 0; j--) {
          if (isNumber(row[`${j}-departure`])) {
            lastStopTimeWithTimes = j
            break
          }
        }
        const isFirstColumn = i === 0 && rowIndex > 0
        const diff = isFirstColumn
          // For cells in the first column, formatting depends on headway /
          // previous row.
          ? row[`${i}-departure`] - rows[rowIndex - 1][`${i}-departure`]
          // For other cells, formatting depends on travel time from last
          // stoptime with times.
          : row[`${i}-departure`] - row[`${lastStopTimeWithTimes}-departure`]
        // Sort data by count.
        const columnData = extraData[`${i}`].sort((a, b) => a.count - b.count)
        const frequencyIndex = columnData.findIndex(e => e.diff === diff)
        const mostFrequentTravelTime = columnData[columnData.length - 1].diff
        // Sort data by travel time.
        columnData.sort((a, b) => a.diff - b.diff)
        const mostFrequentIndex = columnData.findIndex(e => e.diff === mostFrequentTravelTime)
        const travelTimeIndex = columnData.findIndex(e => e.diff === diff)
        const count = travelTimeIndex !== -1 ? columnData[travelTimeIndex].count : rows.length
        // If the cell matches the most frequent travel time (or the occurrence
        // for travel times across the day is equal), make color black.
        // Otherwise, scale lightness by relative frequency.
        const occurrenceIsEqual = columnData.every(e => e.diff === columnData[0].diff)
        const lightPercent = occurrenceIsEqual || frequencyIndex === columnData.length - 1
          ? 0
          : ((rows.length - count) / rows.length) * 40
        const format = travelTimeIndex < mostFrequentIndex
          ? CELL_FORMAT.FASTER
          : travelTimeIndex > mostFrequentIndex
            ? CELL_FORMAT.SLOWER
            : CELL_FORMAT.EQUAL
        const textStyle = {color: `hsl(${format.color}, 100%, ${lightPercent.toString()}%)`}
        const diffFromUsual = humanizeSeconds(Math.abs(mostFrequentTravelTime - diff))
        return (
          <span
            title={travelTimeIndex !== mostFrequentIndex
              ? `${i === 0 ? 'Headway' : 'Travel time'} (${humanizeSeconds(diff)}) is ${diffFromUsual} ${format.text}.`
              : undefined
            }
            style={!isFirstColumn ? textStyle : undefined}>
            {secondsAfterMidnightToHHMM(cell)}
            {isFirstColumn && <span style={textStyle}>{format.icon}</span>}
          </span>
        )
      }
      // add header columns depending on whether arrivals need to be shown
      columns.push({
        colSpan: showArrivals ? '2' : '1',
        csvHeader: stopName,
        dataField: showArrivals ? undefined : `${i}-departure`,
        children: stopSpan,
        dataFormat,
        formatExtraData,
        headerText: stopName,
        hidden: timepointFilter ? !columnHasStopTimeWithTime[`${i}`] : undefined,
        row: '0',
        stopId,
        width: `${STOP_COL_WIDTH}`
      })
      if (showArrivals) {
        columns.push({
          csvHeader: `Arrive`,
          dataField: `${i}-arrival`,
          dataFormat,
          formatExtraData,
          hidden: timepointFilter ? !columnHasStopTimeWithTime[`${i}`] : undefined,
          children: 'Arrivo',
          row: '1',
          stopId,
          width: `${STOP_COL_WIDTH / 2}`
        })
        columns.push({
          csvHeader: `Dwell`,
          dataField: `${i}-dwell`,
          children: 'Attesa',
          hidden: timepointFilter ? !columnHasStopTimeWithTime[`${i}`] : undefined,
          row: '1',
          stopId,
          width: `${STOP_COL_WIDTH / 2}`
        })
      }
    })

    return {columns, rows}
  }
)

function isNumber (n) {
  return typeof n === 'number'
}
